import React from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  FormHelperText,
} from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { Images } from '../assets/images';
import { SignUpFormValues } from '../pages/SignUpPage';

interface SignUpLayoutProps {
  role: string;
  isLoading: boolean;
  form: UseFormReturn<SignUpFormValues, any, undefined>;
  isVerifyOtp: boolean;
  error?: string | null;
  onClickRegister: () => void;
  onClickSignIn: () => void;
  onClickVerifyOTP: () => void;
}

const SignUpLayout = ({
  isLoading,
  role,
  form,
  isVerifyOtp,
  error,
  onClickRegister,
  onClickSignIn,
  onClickVerifyOTP,
}: SignUpLayoutProps) => {
  const { register, formState: { errors } } = form;

  return (
    <Grid container spacing={4} sx={{ padding: '60px' }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Stack direction="column" spacing={3}>
          <img alt="Logo" src={Images.logoLight} style={{ maxHeight: '60px', maxWidth: '150px' }} />
          <Typography variant="h2">Sign Up ({role})</Typography>
          {isVerifyOtp ? (
            <>
              <FormControl fullWidth sx={{ m: 1 }} error={!!errors.verificationCode}>
                <InputLabel htmlFor="verificationCode">Verification Code</InputLabel>
                <OutlinedInput
                  {...register('verificationCode', {
                    required: 'Verification Code is required',
                    pattern: {
                      value: /^\d{6}$/,
                      message: 'Enter a valid 6-digit Verification Code',
                    },
                  })}
                  type='number'
                  id="verificationCode"
                  label="Verification Code"
                />
                {errors.verificationCode?.message && <FormHelperText>{(errors.verificationCode.message as string)}</FormHelperText>}
              </FormControl>
              <Button
                onClick={onClickVerifyOTP}
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={16} />}
                variant="contained"
                fullWidth
              >
                Submit
              </Button>
            </>
          ) : (
            <>
              <FormControl fullWidth sx={{ m: 1 }} error={!!errors.email}>
                <InputLabel htmlFor="email">User Email</InputLabel>
                <OutlinedInput
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Enter a valid email address',
                    },
                  })}
                  id="email"
                  type='email'
                  label="User Email"
                />
                {errors.email?.message && <FormHelperText>{(errors.email.message as string)}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }} error={!!errors.password}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  {...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 6,
                      message: 'Password must be at least 6 characters long',
                    },
                  })}
                  type="password"
                  id="password"
                  label="Password"
                />
                {errors.password?.message && <FormHelperText>{(errors.password.message as string)}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }} error={!!errors.confirmPassword}>
                <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                <OutlinedInput
                  {...register('confirmPassword', {
                    required: 'Confirm password is required',
                    validate: (value) => value === form.getValues('password') || 'Passwords do not match',
                  })}
                  type="password"
                  id="confirmPassword"
                  label="Confirm Password"
                />
                {errors.confirmPassword?.message && <FormHelperText>{(errors.confirmPassword.message as string)}</FormHelperText>}
              </FormControl>

              <Button
                onClick={onClickRegister}
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={16} />}
                variant="contained"
                fullWidth
              >
                Submit
              </Button>
              <Button
                onClick={onClickSignIn}
                variant="contained"
                fullWidth
              >
                Back to sign In
              </Button>
            </>
          )}
        </Stack>
        { error && <FormHelperText sx={{ marginTop: '12px' }} error>{(error as string)}</FormHelperText> }
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <img alt="Banner" src={Images.banner} style={{ maxHeight: '60vh' }} />
      </Grid>
    </Grid>
  );
};

export default SignUpLayout;
