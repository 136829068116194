import React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import BreadCrumbs from '../../components/BreadCrumbs';
import { GridColDef } from '@mui/x-data-grid';
import DataGridContainer from '../../components/DataGrid'
import { DatePicker } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import NewScheduleLayout from './NewScheduleLayout';
import { DoctorScheduleResponse, useDoctorSchedule } from '../../hooks/useDoctorSchedule';
import { useDoctor } from '../../hooks/useDoctor';
import ViewDoctorSlots from './ViewDoctorSlots';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const DoctorScheduleLayout = ({ doctorSchedules }: { doctorSchedules: DoctorScheduleResponse[] }) => {

  const [doctorSchedule, setDoctorSchedule] = React.useState(true)
  const [filteredDoctorSchedules, setFilteredDoctoredSchedules] = React.useState([...doctorSchedules])
  const { getAllDepartments, departments } = useDoctor();
  const [openViewSlots, setOpenViewSlots] = React.useState(false)
  const [doctorDetails , setDoctorDetails] = React.useState({name:'',id:''})
  const {deletedoctorSchedule,getDoctorSchedules} = useDoctorSchedule()

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Doctor',
      width: 450,
      renderCell: (row) => {
        return (
          <Stack direction='row' style={{paddingTop:"4%" }}>
            <Stack direction='column'>
              <Typography >{row?.row?.doctor || 'Dr Name'}</Typography>
              <Typography variant="caption">{row?.row?.department || 'department'}</Typography>
            </Stack>
          </Stack>
        )
      }
    },
    {
      field: 'perpatientTime',
      headerName: 'Per Patient Duration',
      width: 150,
      renderCell: (row) => {
        return (
          <Typography style={{paddingTop:"20%"}}>{row.row.perpatientTime} </Typography>
        )
      }
    },
    {
      field: 'tokenNo',
      headerName: 'Availability',
      width: 550,
      renderCell: (row) => {
        let dateStr = new Date(row.row.date).toLocaleDateString("en-us", { month: "long", year: "numeric", day: "numeric" }) || ''
        return (
          <Stack direction='row' style={{paddingTop:"4%"}}>
            <Stack direction='column'>
              <Typography>{dateStr} | {row?.row?.day} </Typography>
              <Stack direction='row' style={{ flexWrap: 'wrap', width: '60%' }}>
                {row?.row?.slotAvailability?.map((s: any,index:any) => {
                  return (
                    <Typography key={index} variant="overline"> {s?.startTime} - {s?.endTime} ,</Typography>
                  )
                })}
              </Stack>
            </Stack>
            <Stack direction='row' spacing={2} style={{ justifyContent: 'center',marginLeft:"5%" }}>
              <Button variant='contained' style={{ width: '160px',height:'40px' }} onClick={()=>viewSlotHandler(row?.row)}>View Schedule</Button>
              <Button variant='contained' style={{ width: '160px',height:'40px',backgroundColor:'red' }} onClick={()=>deleteScheduleHandler(row?.row)}>Delete Schedule</Button>
            </Stack>
          </Stack>
        )
      }
    }
  ];

  React.useEffect(() => {
    getAllDepartments()
    setFilteredDoctoredSchedules(doctorSchedules)
  }, [doctorSchedules])

  const viewSlotHandler = (doctorDetails:any) => {
    setOpenViewSlots(true)
    setDoctorDetails({name:doctorDetails?.doctor,id:doctorDetails?.doc_id})
  }


  const deleteScheduleHandler = (doctorDetails:any) => {
    // remove window.reload
    deletedoctorSchedule(doctorDetails?.scheduleId).then(()=>window.location.reload()).catch((err)=>console.log(err))
  }

  const validationSchema = yup.object().shape({
    date: yup.date().required('Date is required'),
    department: yup.string().required('Department is required')
  });

  const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: undefined,
      department: ''
    },
  });

  const onSearchHandler = (e: any) => {
    let txt: string = e.target.value;
    if (txt.length >= 1) {
      let searchResult: DoctorScheduleResponse[] = []
      doctorSchedules.map((schedule: any) => {
        if (schedule?.doctor?.substr(0, txt.length)?.toLowerCase() === txt) {
          searchResult.push(schedule)
        }
      })
      setFilteredDoctoredSchedules(searchResult)
    }
    else {
      // if empty update to all values
      setFilteredDoctoredSchedules(doctorSchedules)
    }
  }

  const onTokenDateHandler = (val: any) => {
    let txt = val?._d;
    let result: DoctorScheduleResponse[] = []
    doctorSchedules.map((schedule: any) => {
      let convertedDate = new Date(schedule?.date).toLocaleDateString("en-us", { day: "numeric", month: "long" })
      let convertedTxt = new Date(txt).toLocaleDateString("en-us", { day: "numeric", month: "long" })
      if (convertedDate === convertedTxt) {
        result.push(schedule)
      }
    })
    setFilteredDoctoredSchedules(result)
  }

  const onDepartmentChangeHandler = (e: any) => {
    let txt: string = e.target.value;
    setValue('department', e.target.value);
    let result: DoctorScheduleResponse[] = []
    if (txt === "All") {
      setFilteredDoctoredSchedules(doctorSchedules)
    } else {
      doctorSchedules.map((schedule: any) => {
        if (schedule?.department?.substr(0, txt.length)?.trim()?.toLowerCase() === txt?.trim()?.toLowerCase()) {
          result.push(schedule)
        }
      })
      setFilteredDoctoredSchedules(result)
    }
  }
  return (
    <Box>
      {doctorSchedule ?
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <BreadCrumbs data={['Home', 'Doctor Schedule', !openViewSlots ? 'New Schedule' : 'View Schedule']} title="Doctor Schedule" />
            <Button variant='contained' onClick={() => { setDoctorSchedule(false) }}>New Schedule</Button>
          </Stack>
          <div style={{ marginTop: '24px' }} />
          {!openViewSlots && <div>
          <Typography sx={{ marginTop: '24px', marginBottom: '24px' }} variant='h4'>Appointments</Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center">

            <Stack>

              <TextField id="outlined-basic" variant="outlined" placeholder='Search...' sx={{ width: 600 }}
                onChange={onSearchHandler}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack direction="row" spacing={3}>
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel htmlFor="select-department">Departments</InputLabel>
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <Select id="select-department" label="Departments" {...field} onChange={onDepartmentChangeHandler} value={field.value}   >
                      {[{ label: 'All', value: 'All' }, ...departments]?.flat(Infinity)?.map((item: any, index) => {
                        return (<MenuItem key={index} value={item?.label || ''}>{item?.label || ''}</MenuItem>)
                      })}
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl sx={{ width: 150 }}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => <DatePicker label="Token Date" value={field.value || null} onChange={(newValue) => onTokenDateHandler(newValue)} />}
                />
              </FormControl>

            </Stack>
          </Stack>
          <br />
          <DataGridContainer
            getRowHeight={() => 'auto'}
            columns={columns} rows={filteredDoctorSchedules} getRowId={(row) => row.doc_id}/>
            </div>}
         
            {openViewSlots && <Box>
              <ViewDoctorSlots doctorDetails={doctorDetails} handleClose={()=>setOpenViewSlots(false)}/>
            </Box>}

        </Box>
        :
        <Modal
          open={!doctorSchedule}
          onClose={() => setDoctorSchedule(true)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <NewScheduleLayout setDoctorSchedule={setDoctorSchedule} />
          </Box>
        </Modal>
      }
    </Box>
  )
}

export default DoctorScheduleLayout