import React, { useState } from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import BreadCrumbs from '../../components/BreadCrumbs'
import DataGridContainer from '../../components/DataGrid';
import { HospitalResponse } from '../../hooks/useAdmin';

interface AdminHospitalLayoutProps {
  hospitalList: HospitalResponse | null;
  updateHospitalStatus: (id: string, status: string) => Promise<void>;
}

const AdminHospitalLayout = ({ hospitalList, updateHospitalStatus }: AdminHospitalLayoutProps) => {
  const [selectedId, setSelectedId] = useState('');
  const updateStatus = async (id: string, status: string) => {
    try {
      setSelectedId(id);
      await updateHospitalStatus(id, status);
      setSelectedId('');
    } catch (error) {
      setSelectedId('');
    }
  }
  const columns: GridColDef[] = [
    { field: '_id', headerName: 'Hospital ID', width: 300 },
    {
      field: 'name',
      headerName: 'Hospital name',
      width: 250,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 200,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Time',
      width: 200,
      renderCell:(row)=>{
        let convertedStartTime:any = new Date(row.row.createdAt)
        let day:string = convertedStartTime.toLocaleDateString("en-us",{hour:'numeric',minute:'numeric',hour12:false})
        return(
          <>{day}</>
        )
      } 
    },
    {
      field: 'isApproved',
      headerName: 'Status',
      width: 160,
      renderCell: (row) => (
        <>{(row?.row?.isApproved as string)?.toUpperCase()}</>
      )
    },
    {
      field: 'updateStatus',
      headerName: 'Update status',
      width: 150,
      renderCell: (row) => (
        <Button style={{backgroundColor : (row?.row?.isApproved === 'rejected') || (row?.row?.isApproved === 'pending') ?"green":"red"}} startIcon={row?.row?._id === selectedId && <CircularProgress size={12} /> } onClick={() => updateStatus(row?.row?._id, (row?.row?.isApproved === 'pending' || row?.row?.isApproved === 'rejected') ? 'approved' : 'rejected')} variant='contained'>
          {(row?.row?.isApproved === 'pending' || row?.row?.isApproved === 'rejected') ? 'Approve' : 'Reject'}
        </Button>
      ),
    },
  ];
  return (
    <Box>
      <BreadCrumbs data={['Home', 'Tables', 'OPD Hospital Request']} title="OPD Hospital Request" />
      <Typography marginTop="16px" marginBottom="16px" variant='h4'>Total Hospital Request</Typography>
      { hospitalList?.hospitals && hospitalList?.hospitals?.length > 0 && (
        <DataGridContainer getRowId={(row) => row._id} columns={columns} rows={hospitalList?.hospitals} />
      ) }
    </Box>
  )
}

export default AdminHospitalLayout;
