import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { CustomCall } from "../constants/EndPoints";

export interface ActiveCallRecord {
  id: string;
  message: string;
  hospitalId: string;
  doctorId: {
    id: string;
    name: string;
  };
  roomNo: string;
  isActive: boolean;
  department: string;
  customCallId: string;
}

export const useActiveCall = () => {
  const [loading, setLoading] = useState(false);
  const [activeCalls, setActiveCalls] = useState<ActiveCallRecord[]>([]);

  const getActiveCalls = useCallback(async (hospitalId: string) => {
    try {
      setLoading(true);
      const response = await getHttpClient(
        `${CustomCall.activeCall}?hospitalId=${hospitalId}`,
        "GET"
      );
      setActiveCalls(response?.response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveCalls([]);
    }
  }, []);

  const endCall = useCallback(async (id: string, hospitalId: string) => {
    await getHttpClient(`${CustomCall.activeCall}/${id}`, "PATCH");
    await getActiveCalls(hospitalId);
  }, [getActiveCalls]);

  const values = useMemo(() => {
    return {
      loading,
      activeCalls: activeCalls,
      getActiveCalls,
      endCall,
    };
  }, [loading, activeCalls, getActiveCalls, endCall]);

  return values;
};
