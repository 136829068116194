import React from 'react'
import { Box, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import DataGridContainer from '../../components/DataGrid'
import BreadCrumbs from '../../components/BreadCrumbs';
import { CallRecord } from '../../hooks/useCallLogs';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 250,
       //Reduce "ID" column to 8 bit.
     renderCell:(row)=>{
      return(
        <>{String(row?.row?.id).slice(0,4)}</>
      )
    } 
     },
     { field: 'doctorName', headerName: 'Doctor Name', width: 250,
    renderCell:(row)=>{
     return(
       <>{String(row?.row?.doctorId.name)}</>
     )
   } 
    },
    {
      field: 'message',
      headerName: 'Content',
      width: 250,
    },
    {
      field: 'roomNo',
      headerName: 'Room number',
      width: 150,
    },
    {
      field: 'startTime',
      headerName: 'Start time',
      width: 200,
      renderCell:(row)=>{
        let convertedStartTime:any = new Date(row.row.startTime)
        let output = '-'
        if(row.row.startTime){
          output =  convertedStartTime.toLocaleDateString("en-us",{hour:'numeric',minute:'numeric',hour12:false})
        }
       
        return(
          <>{output}</>
        )
      } 
    },
    {
      field: 'endTime',
      headerName: 'End time',
      width: 200,
      renderCell:(row)=>{
        let convertedendTime:any = new Date(row.row.endTime)
        let output = '-'
        if(row.row.endTime){
          output =  convertedendTime.toLocaleDateString("en-us",{hour:'numeric',minute:'numeric',hour12:false})
        }
        return(
          <>{output}</>
        )
      } 
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 150,
        // adding duration logic
        renderCell:(row)=>{
          let convertedEndTime:any = new Date(row.row.endTime).toLocaleTimeString("en-us",{minute:'numeric',hour:'numeric',second:'numeric',hour12:false})
          let convertedStartTime:any = new Date(row.row.startTime).toLocaleTimeString("en-us",{minute:'numeric',hour:'numeric',second:'numeric',hour12:false})
          let callDuration:any = convertedEndTime.split(":")
          let callDurationStartTime = convertedStartTime.split(":")
          let endTimeSecond = parseInt(callDuration[2])
          let endTimeMinute = parseInt(callDuration[1])
          let endTimeHour = parseInt(callDuration[0])
          let startTimeSecond = parseInt(callDurationStartTime[2])
          let startTimeMinute = parseInt(callDurationStartTime[1])
          let startTimeHour = parseInt(callDurationStartTime[0])
  
          let result = `${Math.abs(endTimeHour-startTimeHour)} H : ${Math.abs(endTimeMinute-startTimeMinute)}M : ${Math.abs(endTimeSecond-startTimeSecond)}S `
          return(
            <>{result}</>
          )
        } 
    },
    {
      field: 'endBy',
      headerName: 'Call Ended By',
      width: 200,
    },
  ];

const LogsLayout = ({ callLogs, name }: { callLogs: CallRecord[], name?: string }) => {
  return (
    <Box>
      <BreadCrumbs data={['Home', 'Doctor', 'Call Logs']} title="Doctor Dashboard" />
      <Typography marginTop="24px" marginBottom="24px" variant='h4'>Doctor Name: {name}</Typography>
      <DataGridContainer columns={columns} rows={callLogs} />
    </Box>
  )
}

export default LogsLayout