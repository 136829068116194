import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { OnBoardingUser } from "../constants/EndPoints";

interface ContactInfoHospital {
    name: string;
    email: string;
    phone: string;
    state: string;
    city: string;
    address: string;
    department: string;
}

interface DoctorProfile {
    name: string;
    hospital: string[];
    phone: string;
    email: string;
    about: string;
    profileImage: string;
    department: string;
}

export const useRegisterUser = () => {
    const [loading, setLoading] = useState(false);
    const [hospitalList, setHospitalList] = useState<{label: string; value: string;}[]>([]);
    const [isApproved, setIsApproved] = useState('')

    const registerDoctor = useCallback(async (data: DoctorProfile) => {
        try {
            setLoading(true);
            const response = await getHttpClient(OnBoardingUser.registerDoctor, 'PATCH', data);
            setIsApproved(response?.response?.isApproved)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const registerHospital = useCallback(async (data: ContactInfoHospital) => {
        try {
            setLoading(true);
            await getHttpClient(OnBoardingUser.registerHospital, 'PATCH', data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const getAllHospital = useCallback(async () => {
        try {
            const response = await getHttpClient(OnBoardingUser.getAllHospital, 'GET');
            const formatResponse = response?.hospitals?.map((item: any) => ({ value: item?._id, label: item?.name }))
            setHospitalList(formatResponse);
        } catch (error) {
            setHospitalList([]);
        }
    }, []);

    const values = useMemo(() => {
        return {
            loading,
            hospitalList,
            getAllHospital,
            registerDoctor,
            registerHospital,
            isApproved
        }
    }, [loading, hospitalList, getAllHospital, registerDoctor, registerHospital,isApproved]);

    return values;
};