import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { DoctorEndPoints } from "../constants/EndPoints";

export const useDoctor = () => {
    const [loading, setLoading] = useState(false);
    const [roomId, setRoomId] = useState<string>('');
    const [departments, setDepartments] = useState<{value: string; label: string}[]>([]);
    const [callList, setCallList] = useState<{id: string; name: string}[]>([]);

    const deleteHospital = useCallback(async (id: string) => {
        try {
            await getHttpClient(`doctor/${id}/deletehospital`, 'POST');
        } catch (error) {
            console.log(error)
        }
    }, []);

    const getRoomId = useCallback(async (id: string) => {
        try {
            const response = await getHttpClient(`${DoctorEndPoints.getRoomById}/${id}`, 'GET');
            setRoomId(response?.data)
        } catch (error) {
            setRoomId('');
        }
    }, []);

    const getAllDepartments = useCallback(async () => {
        try {
            const response = await getHttpClient(DoctorEndPoints.departments, 'GET');
            const filterResponse = response?.department?.map((item: any) => ({ label: item?.name, value: item?._id }))
            setDepartments(filterResponse);
        } catch (error) {
            setDepartments([]);
        }
    }, []);

    const getCustomCall = useCallback(async (id: string) => {
        try {
            const response = await getHttpClient(`${DoctorEndPoints.customCall}?hospitalId=${id}`, 'GET');
            const filterResponse = response?.response?.map((item: any) => ({ id: item?.id, name: item?.message }));
            setCallList(filterResponse);
        } catch (error) {
            setCallList([]);
        }
    }, []);

    const createCustomCall = useCallback(async (data: { message: string; hospitalId: string}) => {
        try {
            await getHttpClient(DoctorEndPoints.customCall, 'POST', data);
            await getCustomCall(data?.hospitalId);
        } catch (error) {
        }
    }, [getCustomCall]);

    const deleteCustomCallById = useCallback(async (id: string, hospitalId: string) => {
        try {
            await getHttpClient(`${DoctorEndPoints.customCall}/${id}`, 'DELETE');
            await getCustomCall(hospitalId);
        } catch (error) {
        }
    }, [getCustomCall]);

    const activeHospital = useCallback(async (id: string) => {
        try {
            await getHttpClient(`doctor/${id}/activehospital`, 'POST');
        } catch (error) {
            console.log(error)
        }
    }, []);

    const values = useMemo(() => {
        return {
            loading,
            roomId,
            departments,
            callList,
            getAllDepartments,
            getRoomId,
            getCustomCall,
            createCustomCall,
            deleteCustomCallById,
            deleteHospital,
            activeHospital
        }
    }, [loading, roomId, departments, callList, deleteCustomCallById, createCustomCall, getCustomCall, getRoomId, getAllDepartments,deleteHospital,activeHospital]);

    return values;
};