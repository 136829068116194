'use client';
import { createTheme, ThemeOptions, Theme } from '@mui/material/styles';
import { font } from './font';
import { appColor } from './palette';

export const theme = createTheme({
  typography: {
    fontFamily: font.primary,
    h1: {
      fontWeight: 700,
      fontSize: '3.88rem',
      color: appColor?.textColor?.secondary,
    },
    h3: {
      fontWeight: 700,
      fontSize: '2rem',
      color: appColor?.textColor?.primary,
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.5rem',
      color: appColor?.textColor?.primary,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '1rem',
      color: appColor?.textColor?.primary,
    },
    body1: {
      fontSize: '1.125rem',
      color: appColor?.textColor?.primary,
      fontWeight: 500,
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      color: appColor?.textColor?.primary,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '1rem',
      color: appColor?.textColor?.primary,
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      color: appColor?.textColor?.primary,
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                    @font-face {
                      font-family: ${font.primary};
                      font-style: normal;
                      font-display: swap;
                      font-weight: 400;
                    }
                  `,
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontFamily: font.primary,
          textTransform: 'none',
          borderRadius: '4px',
          fontWeight: 700,
          fontSize: '1rem',
          backgroundColor: appColor?.primary,
          color: appColor?.textColor?.secondary,
          '&:hover': {
            backgroundColor: appColor?.primary,
            color: appColor?.textColor?.secondary,
            opacity: 0.5,
          },
          '&:disabled': {
            backgroundColor: appColor?.disabled?.background?.primary,
            color: appColor?.disabled?.text?.primary,
          },
        },
        outlined: {
          fontFamily: font.primary,
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '0.9rem',
          background: appColor.background.primary,
          color: appColor?.textColor?.primary,
          border: `1px solid ${appColor?.disabled?.background?.primary}`,
          '&:hover': {
            border: `1px solid ${appColor?.primary}`,
          },
          '&:disabled': {
            border: `1px solid ${appColor?.disabled?.background?.primary}`,
            color: appColor?.disabled?.text?.primary,
          },
        },
        text: {
          fontFamily: font.primary,
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '0.9rem',
          color: appColor?.primary,
          '&:hover': {
            opacity: 0.8,
          },
          '&:disabled': {
            backgroundColor: appColor?.disabled?.background?.primary,
            color: appColor?.disabled?.text?.primary,
          },
        }
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: appColor.primary,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: appColor.textColor.secondary,
            backgroundColor: appColor.primary,
            fontSize: '1rem',
            fontWeight: 500,
          },
          textTransform: 'none',
          backgroundColor: appColor.white?.[400],
          color: appColor.textColor.primary,
          fontSize: '1rem',
          fontWeight: 500,
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: {
            orientation: 'horizontal',
          },
          style: {
            ':before': {
              borderTop: `thin solid ${appColor?.dividerColor}`,
            },
            ':after': {
              borderTop: `thin solid ${appColor?.dividerColor}`,
            },
          },
        },
        {
          props: {
            orientation: 'vertical',
          },
          style: {
            borderColor: appColor?.dividerColor,
          },
        },
      ],
    },
  },
  palette: {
    grey: {
      50: appColor?.grey?.[50],
      100: appColor?.grey?.[100],
      200: appColor?.grey?.[200],
      500: appColor?.grey?.[500],
      600: appColor?.grey?.[600],
    },
    primary: {
      main: appColor?.primary,
    },
    secondary: {
      main: appColor?.background?.secondary,
    },
    backgroundColor: {
      primary: appColor?.background?.primary,
      secondary: appColor?.background?.secondary,
    },
    text: {
      primary: appColor?.textColor?.primary,
      secondary: appColor?.textColor?.secondary,
    },
    black: {
      600: appColor?.black?.[600],
      700: appColor?.black?.[700],
      800: appColor?.black?.[800],
      900: appColor?.black?.[900],
    },
    white: {
      400: appColor?.white?.[400],
    },
    toastError: {
      900: appColor?.error?.[900],
    },
    border: appColor?.border
  },
} as ThemeOptions) as Theme;
