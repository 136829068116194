import React, { useEffect, useState } from 'react';
import {
    Box, Button, CircularProgress, FormControl, InputLabel, useTheme, MenuItem, OutlinedInput, Select, Stack, Typography
} from '@mui/material';
  import { useForm, Controller } from 'react-hook-form';
  import { yupResolver } from '@hookform/resolvers/yup';
  import * as yup from 'yup';
import HospitalDetailCard from '../../components/HospitalDetailCard';
import BreadCrumbs from '../../components/BreadCrumbs';
import { useDoctor } from '../../hooks/useDoctor';
import { HospitalProps, useUserAuth } from '../../provider/AuthProvider';
import { useRegisterUser } from '../../hooks/useRegisterUser';
import { StorageService } from '../../services/StorageService';
import { getHttpClient } from '../../config/AxiosClient';
import { CurrentUser } from '../../constants/EndPoints';
import { stringCapitalize } from '../../utils/StringCapitalize';

const validationSchema = yup.object().shape({
    name: yup.string().required('Full Name is required'),
    phone: yup.string()
      .matches(/^[0-9]+$/, 'Phone Number must be only digits')
      .min(10, 'Phone Number must be at least 10 digits')
      .max(15, 'Phone Number must be at most 15 digits')
      .required('Phone Number is required'),
    profileImage: yup.mixed()
      .test('fileType', 'Only images are allowed', (value: any) => {
        if (value && value[0]) {
          return ['image/jpeg', 'image/png', 'image/gif'].includes(value[0].type);
        }
        return true;
        //return value && value[0] && ['image/jpeg', 'image/png', 'image/gif'].includes(value[0].type);
      }),
    department: yup.string().required('Department is required'),
    about: yup.string().required('About Doctor is required'),
});

const DoctorProfileLayout = () => {
  const theme = useTheme();
  const { departments, getAllDepartments, loading , deleteHospital } = useDoctor();
  const { registerDoctor } = useRegisterUser();
  const { user, setUser } = useUserAuth();
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(user?.profileImage ?? null);
  const [selectedHospital, setSelectedHospital] = useState<HospitalProps[]>(user?.hospital ?? []);
  const [hospitalId, setHospitalId] = useState<string>('');
  const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      about: user?.about ?? '',
      department: user?.department?.[0]?.id,
      name: user?.userName,
      phone: user?.phone,
      profileImage: undefined
    }
  });

  useEffect(() => {
    getAllDepartments();
    // since department value is id , setting it to value
    let getDepartmentValue = getValues()
    setValue('department',getDepartmentValue.department)
  }, []);

  const onProfileImageChange = (files: FileList | null) => {
    if (files && files[0]) {
      const imageUrl = URL.createObjectURL(files[0]);
      setProfileImageUrl(imageUrl);
      setValue('profileImage', files);
    }
  };

  const onSubmit = async (data: any) => {
    if (profileImageUrl) {
      data.profileImage = profileImageUrl;
    }
    await registerDoctor({...data, email: user?.email, hospital: selectedHospital.map(item => item.id)});
    const authToken = StorageService.authToken.getValue();
    if (authToken) {
      const response = await getHttpClient(CurrentUser.me, 'GET');
      const hospitalList = response?.data?.hospital?.map((item: any) => ({ id: item?._id, name: item?.name, city: item?.city }));
      const role: any = stringCapitalize(response?.role);
      const departmentList = response?.data?.department?.map((item: any) => ({ id: item?._id, name: item?.name }));
      setUser({
        role,
        id: response?.user?.id,
        userName: response?.user?.name,
        isApproved: response?.data?.isApproved,
        isOnboard: response?.data?.isOnboard,
        email: response?.data?.email,
        hospital: hospitalList,
        department: departmentList,
        authToken,
        phone: response?.data?.phone,
        about: response?.data?.about ?? '',
        profileImage: response?.data?.profileImage ?? '',
      });
    }
  };

  const onClickRemove = async (item: HospitalProps) => {
    const data = selectedHospital.filter(e => e.id !== item.id);
    setSelectedHospital(data);
    await deleteHospital(item.id)
  }

  const onClickAdd = async () => {
    const formData = getValues();
    const newData = [...selectedHospital.map(item => item.id), hospitalId];
    if (formData) {
      await registerDoctor({...formData, profileImage: profileImageUrl ?? '', email: user?.email ?? '', hospital: newData});
      const authToken = StorageService.authToken.getValue();
      if (authToken) {
        const response = await getHttpClient(CurrentUser.me, 'GET');
        const hospitalList = response?.data?.hospital?.map((item: any) => ({ id: item?._id, name: item?.name, city: item?.city }));
        const role: any = stringCapitalize(response?.role);
        const departmentList = response?.data?.department?.map((item: any) => ({ id: item?._id, name: item?.name }));
        setUser({
          role,
          id: response?.user?.id,
          userName: response?.user?.name,
          isApproved: response?.data?.isApproved,
          isOnboard: response?.data?.isOnboard,
          email: response?.data?.email,
          hospital: hospitalList,
          department: departmentList,
          authToken,
          phone: response?.data?.phone,
          about: response?.data?.about ?? '',
          profileImage: response?.data?.profileImage ?? '',
        });
        setSelectedHospital(hospitalList);
      }
    }
  }

  return (
    <Stack direction="column" spacing={3}>
        <BreadCrumbs data={['Home', 'Doctor', 'Department']} title="Doctor Profile" />
        <Typography variant='h4'>Doctor details</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ marginBottom: '16px' }} fullWidth error={!!errors.name}>
          <InputLabel htmlFor="outlined-adornment-name">Full Name</InputLabel>
          <Controller
            name="name"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-name" label="Full Name" {...field} />}
          />
          {errors.name && <Typography color="error">{errors.name.message}</Typography>}
        </FormControl>
        <FormControl sx={{ marginBottom: '16px' }} fullWidth error={!!errors.phone}>
          <InputLabel htmlFor="outlined-adornment-phone">Phone Number</InputLabel>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-phone" label="Phone Number" {...field} />}
          />
          {errors.phone && <Typography color="error">{errors.phone.message}</Typography>}
        </FormControl>
        <Typography>Profile Image</Typography>
        <FormControl sx={{ marginBottom: '16px' }} fullWidth error={!!errors.profileImage}>
          <Controller
            name="profileImage"
            control={control}
            render={({ field }) => (
              <>
                <input
                  style={{ border: `1px solid rgb(0 0 0 / 23%)`, padding: '18px', borderRadius: '4px', cursor: 'pointer' }}
                  id="outlined-adornment-image"
                  type="file"
                  accept="image/*"
                  onChange={(e) => onProfileImageChange(e.target.files)}
                />
                {profileImageUrl && <img src={profileImageUrl} alt="Profile Preview" style={{ marginTop: '10px', maxWidth: '100px' }} />}
              </>
            )}
          />
          {errors.profileImage && <Typography color="error">{errors.profileImage.message}</Typography>}
        </FormControl>
        <FormControl sx={{ marginBottom: '16px' }} fullWidth error={!!errors.department}>
          <InputLabel htmlFor="select-department">Departments</InputLabel>
          <Controller
            name="department"
            control={control}
            render={({ field }) => (
              <Select id="select-department" label="Departments" {...field}>
                { departments?.map(item => (
                    <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                )) }
              </Select>
            )}
          />
          {errors.department && <Typography color="error">{errors.department.message}</Typography>}
        </FormControl>
        <FormControl sx={{ marginBottom: '16px' }} fullWidth error={!!errors.about}>
          <InputLabel htmlFor="outlined-adornment-about">About Doctor</InputLabel>
          <Controller
            name="about"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                id="outlined-adornment-about"
                label="About Doctor"
                multiline
                rows={3}
                {...field}
              />
            )}
          />
          {errors.about && <Typography color="error">{errors.about.message}</Typography>}
        </FormControl>
        <Box sx={{ marginBottom: '16px' }}>
            <Typography marginBottom="6px" variant='subtitle1'>Enter hospital ID</Typography>
            <Typography color={theme.palette.black?.[700]} marginBottom="12px" variant='body1'>You can find the hospital ID by asking the hospital or contacting support.</Typography>
            <Stack direction="row" spacing={2}>
                <FormControl>
                    <InputLabel htmlFor="outlined-adornment-hospitalId">Hospital ID</InputLabel>
                    <OutlinedInput
                      onChange={e => setHospitalId(e.target.value)}
                      value={hospitalId}
                      id="outlined-adornment-hospitalId"
                      label="Hospital ID"
                    />
                </FormControl>
                <Button startIcon={loading && <CircularProgress size={16} />} disabled={loading} onClick={onClickAdd} variant='contained'>Add</Button>
            </Stack>
        </Box>
        { selectedHospital?.map(item => (
          <HospitalDetailCard data={item} onClickRemove={() => onClickRemove(item)} />
        )) }
        <Stack sx={{ marginTop: '16px' }} direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Button startIcon={loading && <CircularProgress size={16} />} disabled={loading} type='submit' variant='contained'>Submit</Button>
        </Stack>
        </form>
    </Stack>
  )
}

export default DoctorProfileLayout