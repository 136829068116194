import React, { useMemo } from 'react';
import Box from "@mui/material/Box";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

interface BreadCrumbsProps {
  data: string[];
  title: string;
}

const BreadCrumbs = ({ data, title }: BreadCrumbsProps) => {
  const theme = useTheme();
  const breadcrumbs = useMemo(() => {
    return data.map((item, index) => <Typography key={index}>{item}</Typography>);
  }, [data]);
  return (
    <Box>
      <Typography variant='h2'>{title}</Typography>
      <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" sx={{ color: theme.palette.text.primary }} />}
          aria-label="breadcrumb"
      >
          {breadcrumbs}
      </Breadcrumbs>
      <Divider sx={{ marginTop: '16px' }} />
    </Box>
  )
}

export default BreadCrumbs