import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import BreadCrumbs from '../../components/BreadCrumbs'
import DataGridContainer from '../../components/DataGrid';
import { CountData, HospitalResponse } from '../../hooks/useAdmin';
import CountCard from '../../components/CountCard';

const columns: GridColDef[] = [
  { field: "_id", headerName: "Hospital ID", width: 300 },
  {
    field: "name",
    headerName: "Hospital name",
    width: 250,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
  {
    field: "state",
    headerName: "State",
    width: 200,
  },
  {
    field: "city",
    headerName: "City",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Date",
    width: 150,
    renderCell: (row) => (
      <>{new Date(row?.row?.createdAt as string).toLocaleDateString("en-us")}</>
    ),
  },
  {
    field: "createdAt1",
    headerName: "Time",
    width: 150,
    renderCell: (row) => (
      <>
        {new Date(row?.row?.createdAt as string).toLocaleTimeString("en-us", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })}
      </>
    ),
  },
  {
    field: "isApproved",
    headerName: "Status",
    width: 160,
    renderCell: (row) => <>{(row?.row?.isApproved as string)?.toUpperCase()}</>,
  },
];

const AdminDashboardLayout = ({ hospitalList, count }: { hospitalList: HospitalResponse | null; count: CountData }) => {
  return (
    <Box>
      <BreadCrumbs data={['Home', 'OPD Dashboard']} title="OPD Dashboard" />
      <Grid marginTop={2} marginBottom={4} container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CountCard title='Doctors | Onboarding' count={count?.totalDoctors} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CountCard title='Hospitals | Onboarding' count={count?.totalHospitals} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CountCard title='Approved Hospitals' count={count?.approvedHospitals} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CountCard title='Approved Doctors' count={count?.approvedDoctors} />
        </Grid>
      </Grid>
      <Typography marginTop="16px" marginBottom="16px" variant='h4'>Total Hospital List</Typography>
      { hospitalList?.hospitals && hospitalList?.hospitals?.length > 0 && (
        <DataGridContainer getRowId={(row) => row._id} columns={columns} rows={hospitalList?.hospitals} />
      ) }
    </Box>
  )
}

export default AdminDashboardLayout;
