import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';

interface DataGridContainerProps extends DataGridProps {
    columns: GridColDef[];
}

const DataGridContainer = ({ columns, ...gridProps }: DataGridContainerProps) => {
    return (
      <Box sx={{ width: '100%' }}>
        <DataGrid
          {...gridProps}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,25,50]}
          autoHeight
          pagination
          localeText={{ noRowsLabel: "" }}
        />
      </Box>
    );
}

export default DataGridContainer;
