import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput, Stack, Typography, useTheme } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import BreadCrumbs from '../../components/BreadCrumbs';
import { useDoctor } from '../../hooks/useDoctor';
import { useUserAuth } from '../../provider/AuthProvider';

interface Field {
  id: string;
  name: string;
}

interface FormValues {
  fields: Field[];
}

const SettingsLayout = () => {
  const theme = useTheme();
  const { user } = useUserAuth();
  const { callList, createCustomCall, getCustomCall, deleteCustomCallById } = useDoctor();
  const { control, formState: { errors }, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      fields: [{ id: '', name: '' }]
    }
  });
  const [fields, setFields] = useState<Field[]>([{ id: '', name: '' }]);
  const [loading, setLoading] = useState<number | null>(null);

  useEffect(() => {
    if (callList) {
      setFields(callList);
    }
  }, [callList]);

  useEffect(() => {
    if (user?.hospital) {
      getCustomCall(user?.activeHospital?.id || user?.hospital?.[0]?.id);
    }
  }, [user]);

  const addField = () => {
    const newField = { id: '', name: '' };
    setFields([...fields, newField]);
    setValue('fields', [...fields, newField]);
  };

  const removeField = async (id: string) => {
    try {
      await deleteCustomCallById(id, user?.activeHospital?.id || user?.hospital?.[0]?.id);
    } catch (error) {
    }
  };

  const saveField = async (index: number) => {
    try {
      setLoading(index);
      const fieldToSave = watch(`fields.${index}`);
      await createCustomCall({ hospitalId: user?.activeHospital?.id || user?.hospital?.[0]?.id, message: fieldToSave?.name });
      setLoading(null);
    } catch (error) {
      setLoading(null);
    }
  };

  return (
    <Stack direction="column" spacing={3}>
      <BreadCrumbs data={['Home', 'Doctor', 'Settings']} title="Settings" />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant='h4'>Customize Content</Typography>
        <Button variant='contained' startIcon={<AddCircleOutlineIcon />} onClick={addField}>Add Tab</Button>
      </Stack>
      <form onSubmit={handleSubmit(() => saveField(-1))}>
        {fields.map((field:any, index) => (
          <Stack key={field.id || index} direction="row" spacing={3} alignItems="center" mb={2}>
            <FormControl sx={{ width: '30%' }} error={!!errors.fields?.[index]?.name}>
              <InputLabel htmlFor={`outlined-adornment-name${field.id || index}`}>Message</InputLabel>
              <Controller
                name={`fields.${index || field.id}.name`}
                control={control}
                defaultValue={field?.name || ""}
                rules={{ required: 'Field is required' }}
                render={({ field: controllerField }) => (
                  <OutlinedInput
                    {...controllerField}
                    id={`outlined-adornment-name${field.name}`}
                    label="Message"
                    value={controllerField.value || ""}
                    onChange={(e) => {
                      controllerField.onChange(e);
                      const updatedFields = [...fields];
                      updatedFields[index].name = e.target.value;
                      setFields(updatedFields);
                      setValue("fields",updatedFields)
                    }}
                  />
                )}
              />
              {errors.fields?.[index]?.name && (
                <Typography variant="caption" color="error">
                  {errors.fields[index]?.name?.message}
                </Typography>
              )}
            </FormControl>
            <Button
              variant='contained'
              startIcon={ loading === index ? <CircularProgress size={12} /> : <SaveAltIcon />}
              onClick={(e) => {
                e.preventDefault();
                saveField(index);
              }}
            >
              Save
            </Button>
            <IconButton
              sx={{ background: theme.palette.primary.main, borderRadius: '8px', minWidth: '60px' }}
              onClick={() => removeField(field.id)}
            >
              <DeleteIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
          </Stack>
        ))}
      </form>
    </Stack>
  );
};

export default SettingsLayout;
