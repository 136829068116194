import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SignUpLayout from '../layout/SignUpLayout';
import { useAuth } from '../hooks/useAuth';
import { Roles } from '../constants/Roles';

export interface SignUpFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  verificationCode: string;
}

const SignUpPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { register, verifyOTP } = useAuth();

  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<SignUpFormValues>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      verificationCode: '',
    },
  });

  const role = useMemo(() => {
    if (location.pathname.includes('hospital')) {
      return Roles.hospital;
    }
    if (location.pathname.includes('doctor')) {
      return Roles.doctor;
    }
    return Roles.admin;
  }, [location]);

  const verifyOtp = form.handleSubmit(async () => {
    try {
      setError(null);
      setIsLoading(true);
      await verifyOTP(role, form.getValues().verificationCode);
      setIsVerifyOtp(false);
      setIsLoading(false);
    } catch (e: any) {
      setError(e?.response?.message || JSON.stringify(e?.response));
      setIsLoading(false);
    }
  });

  const onClickRegister = form.handleSubmit(async (data: SignUpFormValues) => {
    try {
      setError(null);
      setIsLoading(true);
      await register(role, data.email, data.password, data.confirmPassword);
      setIsVerifyOtp(true);
      setIsLoading(false);
    } catch (e: any) {
      setError(e?.response?.message || JSON.stringify(e?.response));
      setIsLoading(false);
    }
  });

  const onClickSignIn = () => {
    navigate("/")
  }

  return (
    <SignUpLayout
      role={role}
      isLoading={isLoading}
      form={form}
      isVerifyOtp={isVerifyOtp}
      error={error}
      onClickRegister={onClickRegister}
      onClickSignIn={onClickSignIn}
      onClickVerifyOTP={verifyOtp}
    />
  );
};

export default SignUpPage;
