import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { HospitalEndPoints } from "../constants/EndPoints";
import { useUserAuth } from "../provider/AuthProvider";
  
export interface CallRecord {
    id: string;
    message: string;
    hospitalId: string;
    doctorId: string;
    isActive: boolean;
}

export const useDepartment = () => {
    const { setUser } = useUserAuth();
    const [loading, setLoading] = useState(false);
    const [departmentList,setDepartmentList] = useState<any>([])
    const [planList ,setPlanList] = useState<string>("")

    const updateDepartment = useCallback(async (department: string[]) => {
        try {
            setLoading(true);
            const res = await getHttpClient(HospitalEndPoints.departments, 'PUT', { department });

            const departmentList = res?.hospital?.department?.map((item: any) => ({
                id: item?._id,
                name: item?.name,
            }));

            // update the user context department
            setUser((prev: any) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  department: departmentList,
                };
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [setUser]);

    const getDepartment = useCallback(async () => {
        try {
            const response = await getHttpClient(`/departments`, 'GET');
            const filterResponse = response?.department?.map((item:any)=>({id:item?.id,name:item?.name}))
            setDepartmentList(filterResponse);
        } catch (error) {
            setDepartmentList([]);
        }
    }, []);

    const createDepartment = useCallback(async (data: { name: string}) => {
        try {
            await getHttpClient('/departments', 'POST', data);
            await getDepartment();
        } catch (error) {
        }
    }, [getDepartment]);

    const savePlan = useCallback(async(payload:any) => {
        try {
            await getHttpClient('/plan','POST',payload)
        }catch(error){
            console.log(error)
        }
    },[])

    const getPlan = useCallback(async () => {
        try {
            const response = await getHttpClient(`/plan`, 'GET');
            setPlanList(response?.response[0]?.plan || "")
            await getDepartment()
        } catch (error) {
            setPlanList("");
        }
    }, []);

    const deleteDepartmentById = useCallback(async (id: string) => {
        try {
            await getHttpClient(`/departments/${id}`, 'DELETE');
            await getDepartment();
            await getPlan()
        } catch (error) {
        }
    }, [getDepartment]);

    const values = useMemo(() => {
        return {
            loading,
            updateDepartment,
            departmentList,
            createDepartment,
            getDepartment,
            deleteDepartmentById,
            savePlan,
            getPlan,
            planList
        }
    }, [loading, updateDepartment,departmentList,savePlan,getPlan,planList]);

    return values;
};