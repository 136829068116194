export const RouterName = {
    home: '/',
    doctor: {
        login: '/auth/login/doctor',
        register: '/auth/register/doctor',
        registerDoctor: '/register/doctor/details',
        profile: '/doctor/profile',
        dashboard: '/doctor/dashboard',
        settings: '/doctor/settings',
        logs:'/doctor/logs',
    },
    hospital: {
        login: '/auth/login/hospital',
        registerHospital: '/register/hospital/details',
        activeCall: '/token/active-calls',
        callLogs: '/token/call-logs'
    },
    admin: {
        login: '/auth/login/admin',
        dashboard: '/admin/dashboard',
        doctor: '/admin/doctor',
    },
    tokenSystem: {
        dashboard: '/token-system',
    }
};
  