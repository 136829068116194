export const appColor = {
  primary: '#16A34A',
  dividerColor: '#DADADA',
  textColor: {
    primary: '#000000',
    secondary: '#FFFFFF',
    tertiary: '#FAFBFB',
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#D7D8DB',
  },
  disabled: {
    background: {
      primary: '#D7D8DB',
    },
    text: {
      primary: '#FAFBFB',
    },
  },
  black: {
    600: '#DADADA',
    700: '#6C6C6C',
    800: '#3C3C3C',
    900: '#0B0B0B',
  },
  grey: {
    50: '#FAFBFB',
    100: '#F5F5F5',
    200: '#D7D8DB',
    500: '#ECECEC',
    600: '#616161',
  },
  error: {
    900: '#BA1319',
  },
  white: {
    400: '#F7F7F7',
  },
  border: '#e5e7eb'
};
