import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useForm } from 'react-hook-form';
import LoginLayout from '../layout/LoginLayout';
import { Roles } from '../constants/Roles';

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginPage = () => {
  const location = useLocation();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const role = useMemo(() => {
    if (location.pathname.includes('hospital')) {
      return Roles.hospital;
    }
    if (location.pathname.includes('doctor')) {
      return Roles.doctor;
    }
    if (location.pathname.includes('token')) {
      return Roles.tokenSystem;
    }
    if (location.pathname.includes('admin')) {
      return Roles.admin;
    }
    return Roles.admin;
  }, [location]);

  const onClickRegister = useCallback(() => {
    if (role === Roles.doctor) {
      navigate('/auth/register/doctor');
    }
    if (role === Roles.hospital) {
      navigate('/auth/register/hospital');
    }
  }, [role, navigate]);

  const handleLogin = form.handleSubmit(async (data: LoginFormValues) => {
    try {
      setError(null);
      setIsLoading(true);
      const response = await login(role, data.email, data.password);
      if (response?.includes('error')) {
        setError('Something went wrong, please contact admin');
      }
      setIsLoading(false);
      form.reset();
    } catch (e: any) {
      setError(e?.response?.message || JSON.stringify(e?.response));
      setIsLoading(false);
    }
  });

  return (
    <LoginLayout
      role={role}
      isLoading={isLoading}
      form={form}
      error={error}
      onClickLogin={handleLogin}
      onClickRegister={onClickRegister}
    />
  );
};

export default LoginPage;
