type VoidCallback = () => void;

class SessionService {
  sessionCallback?: VoidCallback;
  observe(callback: VoidCallback) {
    this.sessionCallback = callback;
  }
  unAuthenticated() {
    this.sessionCallback?.();
  }
}

export default new SessionService();
