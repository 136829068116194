import { Box,Typography } from "@mui/material";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const Waiting = () => {
    return (
        <Box style={{display:'flex',justifyContent:"center",alignItems:'center',height:'100vh'}}>
            <Header/>
            <Sidebar/>
        <Typography variant="h4">
            Waiting for Admin Approval
        </Typography><br/><br/>
        </Box>
    )
}

export default Waiting