import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton, OutlinedInput, Stack } from '@mui/material';
import { ItemDetails } from '../hooks/useDoctorList';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
};

interface ModifyRoomModalProps {
    open: boolean;
    selectedDetails: ItemDetails | null;
    isLoading: boolean;
    handleClose: () => void;
    saveRoom: (id: string, room: number) => Promise<void>;
}

 const ModifyRoomModal = ({ open, selectedDetails, isLoading, handleClose, saveRoom }: ModifyRoomModalProps) => {
  const [roomNo, setRoomNo] = React.useState('');
  const onChangeRoom = React.useCallback((e: string) => {
    setRoomNo(e);
  }, []);
  const onClickSave = React.useCallback(async () => {
    if (selectedDetails) {
        await saveRoom(selectedDetails?.id, +roomNo);
        handleClose();
    }
  }, [saveRoom, handleClose, selectedDetails, roomNo]);
  React.useEffect(() => {
    if (selectedDetails?.roomNo) {
        setRoomNo(selectedDetails?.roomNo);
    }
  }, [selectedDetails]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack padding='8px' direction='row' justifyContent='flex-end' alignItems='flex-end'>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            padding: '4px 24px 24px 24px',
          }}>
          <Typography id="modal-modal-title" variant="h4">
            Add/Edit Rooms
          </Typography>
          <Typography id="modal-modal-description" variant='body1'>
            Assign room for the doctor
          </Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant='body1'>Name: <b>{selectedDetails?.name}</b></Typography>
          </Stack>
          <Box>
            <Typography marginBottom="6px" variant='body1'>Room No</Typography>
            <OutlinedInput fullWidth placeholder='Enter Room No' id="outlined-adornment-room-no" value={roomNo} onChange={e => onChangeRoom(e.target.value)} />
          </Box>
          <Stack padding='8px' direction='row' justifyContent='flex-end' alignItems='flex-end'>
            <Button startIcon={isLoading && <CircularProgress size={16} />} variant='contained' onClick={onClickSave}>Save</Button>
          </Stack>
        </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ModifyRoomModal;
