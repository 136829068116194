import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, CircularProgress, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, Typography
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRegisterUser } from '../hooks/useRegisterUser';
import { RouterName } from '../constants/RouteName';
import { useNavigate } from 'react-router-dom';
import { useDoctor } from '../hooks/useDoctor';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const validationSchema = yup.object().shape({
  name: yup.string().required('Full Name is required'),
  email: yup.string().required('Email is required').matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Enter a valid email'),
  phone: yup.string()
    .matches(/^[0-9]+$/, 'Phone Number must be only digits')
    .min(10, 'Phone Number must be at least 10 digits')
    .max(15, 'Phone Number must be at most 15 digits')
    .required('Phone Number is required'),
  profileImage: yup.mixed()
    .test('fileType', 'Only images are allowed', (value: any) => {
      return value && value[0] && ['image/jpeg', 'image/png', 'image/gif'].includes(value[0].type);
    }),
  department: yup.string().required('Department is required'),
  about: yup.string().required('About Doctor is required'),
  //hospital: yup.array().of(yup.string().required('Hospital ID is required')).min(1, 'At least one Hospital ID is required')
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DoctorRegisterForm = () => {
  const navigate = useNavigate();
  const { getAllDepartments, departments } = useDoctor();
  const { loading, hospitalList, registerDoctor, getAllHospital,isApproved } = useRegisterUser();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      //hospital: []
    }
  });

  useEffect(() => {
    getAllHospital();
    getAllDepartments();
  }, []);

  useEffect(()=>{
    if(isApproved === 'pending'){
      navigate('/waiting')
    } if(isApproved === 'approved'){
      navigate(RouterName.doctor.login)
    }
  },[isApproved])

  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);

  const onProfileImageChange = (files: FileList | null) => {
    if (files && files[0]) {
      const imageUrl = URL.createObjectURL(files[0]);
      setProfileImageUrl(imageUrl);
      setValue('profileImage', files);
    }
  };

  const notify = (params:string) => toast(params, {
    autoClose: false,
  });

  const onSubmit = async (data: any) => {
    if (profileImageUrl) {
      data.profileImage = profileImageUrl;
    }
    await registerDoctor(data);
    notify("Account created successfully!")
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={3}>
        <Typography variant='h4'>Register Doctor</Typography>
        <FormControl fullWidth error={!!errors.name}>
          <InputLabel htmlFor="outlined-adornment-name">Full Name</InputLabel>
          <Controller
            name="name"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-name" label="Full Name" {...field} />}
          />
          {errors.name && <Typography color="error">{errors.name.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.email}>
          <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
          <Controller
            name="email"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-email" label="Email" {...field} />}
          />
          {errors.email && <Typography color="error">{errors.email.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.phone}>
          <InputLabel htmlFor="outlined-adornment-phone">Phone Number</InputLabel>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-phone" label="Phone Number" {...field} />}
          />
          {errors.phone && <Typography color="error">{errors.phone.message}</Typography>}
        </FormControl>
        <Typography>Profile Image</Typography>
        <FormControl fullWidth error={!!errors.profileImage}>
          <Controller
            name="profileImage"
            control={control}
            render={({ field }) => (
              <>
                <input
                  style={{ border: `1px solid rgb(0 0 0 / 23%)`, padding: '18px', borderRadius: '4px', cursor: 'pointer' }}
                  id="outlined-adornment-image"
                  type="file"
                  accept="image/*"
                  onChange={(e) => onProfileImageChange(e.target.files)}
                />
                {profileImageUrl && <img src={profileImageUrl} alt="Profile Preview" style={{ marginTop: '10px', maxWidth: '100px' }} />}
              </>
            )}
          />
          {errors.profileImage && <Typography color="error">{errors.profileImage.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.department}>
          <InputLabel htmlFor="select-department">Departments</InputLabel>
          <Controller
            name="department"
            control={control}
            render={({ field }) => (
              <Select id="select-department" {...field}>
                { departments?.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                )) }
              </Select>
            )}
          />
          {errors.department && <Typography color="error">{errors.department.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.about}>
          <InputLabel htmlFor="outlined-adornment-about">About Doctor</InputLabel>
          <Controller
            name="about"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                id="outlined-adornment-about"
                label="About Doctor"
                multiline
                rows={3}
                {...field}
              />
            )}
          />
          {errors.about && <Typography color="error">{errors.about.message}</Typography>}
        </FormControl>
        {/* Hiding Hospital dropdown as no needed in sign up page
        <FormControl fullWidth error={!!errors.hospital}>
          <InputLabel id="multiple-checkbox-hospital">Hospitals</InputLabel>
          <Controller
            name="hospital"
            control={control}
            render={({ field }) => (
              <Select
                labelId="multiple-checkbox-hospital"
                id="multiple-checkbox-hospital"
                multiple
                value={field.value || []}
                onChange={(e) => setValue('hospital', e.target.value as string[])}
                input={<OutlinedInput label="Hospitals" />}
                renderValue={(selected) => hospitalList?.filter(item => selected?.includes(item?.value))?.map(li => li.label).join(', ')}
                MenuProps={MenuProps}
              >
                {hospitalList?.map((item) => (
                  <MenuItem key={item?.value} value={item?.value}>
                    <Checkbox checked={(field.value || []).indexOf(item?.value) > -1} />
                    <ListItemText primary={item?.label} />
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.hospital && <Typography color="error">{errors.hospital.message}</Typography>}
        </FormControl>
                */}
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Button startIcon={loading && <CircularProgress size={16} />} variant='contained' type="submit">Submit</Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default DoctorRegisterForm;
