import React, { useEffect } from "react";
import ActiveCallsLayout from "../../layout/hospital/ActiveCallsLayout";
import { useActiveCall } from "../../hooks/useActiveCall";
import { useUserAuth } from "../../provider/AuthProvider";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

const ActiveCallsPage = () => {
  const { user } : any = useUserAuth();
  const notify = (params: string) => toast(params, {
    autoClose: false,
  });

  const { loading, activeCalls, getActiveCalls, endCall } = useActiveCall();
  const { connectionStatus , lastMessage } = useSocketContext();

  useEffect(() => {
    if (user?.id ) {
      getActiveCalls(user.id);
    }
  }, [user?.id]);


  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = lastMessage.data;
      const msg = JSON.parse(message);
 
      if(msg.topic === 'active_call' && user?.id === msg.hospitalId && msg.from === 'doctor'){
          getActiveCalls(user?.id)
      }
    }
  }, [connectionStatus, lastMessage]);

  const sleep = (ms:number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  return (
    <>
      <ActiveCallsLayout
        loading={loading}
        activeCalls={activeCalls}
        endCall={(e) => endCall(e, user?.id ?? '')}
      />
    </>

  );
};

export default ActiveCallsPage;
