import React from 'react';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRegisterUser } from '../hooks/useRegisterUser';
import { useNavigate } from 'react-router-dom';
import { RouterName } from '../constants/RouteName';
import { StateList } from '../constants/StateList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validationSchema = yup.object().shape({
  name: yup.string().required('Full Name is required'),
  email: yup.string().required('Email is required').matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Enter a valid email'),
  phone: yup.string()
    .matches(/^[0-9]+$/, 'Phone Number must be only digits')
    .min(10, 'Phone Number must be at least 10 digits')
    .max(15, 'Phone Number must be at most 15 digits')
    .required('Phone Number is required'),
  state: yup.string().required('State is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required')
});

const HospitalRegisterForm = () => {
  const navigate = useNavigate();
  const { loading, registerHospital } = useRegisterUser();
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const notify = (params:string) => toast(params, {
    autoClose: 1000,
    onClose: () => navigate(RouterName.hospital.login),
  });

  const onSubmit = async (data: any) => {
    notify("Account created successfully!")
    await registerHospital(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={3}>
        <Typography variant='h4'>Register Hospital</Typography>
        <FormControl fullWidth error={!!errors.name}>
          <InputLabel htmlFor="outlined-adornment-name">Hospital Name</InputLabel>
          <Controller
            name="name"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-name" label="Full Name" {...field} />}
          />
          {errors.name && <Typography color="error">{errors.name.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.email}>
          <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
          <Controller
            name="email"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-email" label="Email" {...field} />}
          />
          {errors.email && <Typography color="error">{errors.email.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.phone}>
          <InputLabel htmlFor="outlined-adornment-phone">Phone Number</InputLabel>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-phone" label="Phone Number" {...field} />}
          />
          {errors.phone && <Typography color="error">{errors.phone.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.state}>
          <InputLabel htmlFor="select-state">State</InputLabel>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select id="select-state" {...field}>
                { StateList.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                )) }
              </Select>
            )}
          />
          {errors.address && <Typography color="error">{errors.address.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.city}>
          <InputLabel htmlFor="outlined-adornment-city">City</InputLabel>
          <Controller
            name="city"
            control={control}
            render={({ field }) => <OutlinedInput id="outlined-adornment-city" label="City" {...field} />}
          />
          {errors.city && <Typography color="error">{errors.city.message}</Typography>}
        </FormControl>
        <FormControl fullWidth error={!!errors.address}>
          <InputLabel htmlFor="outlined-adornment-address">Address</InputLabel>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                id="outlined-adornment-address"
                label="Address"
                multiline
                rows={3}
                {...field}
              />
            )}
          />
          {errors.address && <Typography color="error">{errors.address.message}</Typography>}
        </FormControl>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Button startIcon={loading && <CircularProgress size={16} />} variant='contained' type="submit">Submit</Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default HospitalRegisterForm;
