import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { DoctorEndPoints, Room } from "../constants/EndPoints";

interface Department {
    _id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  }
  
export interface DoctorData {
    _id: string;
    email: string;
    department: Department[];
    name: string;
    id: string;
    room: string;
    fees: string;
}

export interface ItemDetails {
    id: string;
    name: string;
    roomNo: string;
    department: string;
    departmentId: string;
}

export const useDoctorList = () => {
    const [doctorList, setDoctorList] = useState<ItemDetails[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getDoctorList = useCallback(async (departmentId?: string) => {
        try {
            const params: any = {};
            if (departmentId) params.departmentId = departmentId;
            setLoading(true);
            const response = await getHttpClient(DoctorEndPoints.doctorList, 'GET', undefined, Object.keys(params).length ? params : undefined);
            const formattedResponse = response?.doctors?.map((item: DoctorData) => ({
                id: item?._id,
                name: item?.name,
                roomNo: item?.room,
                department: item?.department?.[0]?.name || "Not allocated",
                departmentId: item?.department?.[0]?._id || "Not allocated",
            }))
            setDoctorList(formattedResponse);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const saveRoomForDoctor = useCallback(async (doctorId: String, roomNo: number) => {
        try {
            setLoading(true);
            await getHttpClient(Room.rooms, 'POST', { doctorId, roomNo });
            await getDoctorList();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [getDoctorList]);

    const values = useMemo(() => {
        return {
            loading,
            doctorList,
            getDoctorList,
            saveRoomForDoctor,
        }
    }, [loading, doctorList, getDoctorList, saveRoomForDoctor]);

    return values;
};