import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { Settings } from "../constants/EndPoints";

export const useSettings = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [settingsData,setSettingsData] = useState([])

  const customRegisters = useCallback(
    async (payload: any) => {
      try {
        setLoading(true);
        await getHttpClient(Settings.customRegisters, "POST", payload);
        setLoading(false);
        getCustomRegisters()
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  const getCustomRegisters = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await getHttpClient(Settings.customRegisters, "GET");
        const output:any = []
        response.response.map((res:any)=>
          {
            output.push({name:res.label,id:res.id})
          }
        )
        setSettingsData(output)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  const deleteCustomRegisters = useCallback(
    async (id:any) => {
      try {
        setLoading(true);
        await getHttpClient(`${Settings.customRegisters}/${id}`, "DELETE");
        getCustomRegisters()
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  const values = useMemo(() => {
    return {
      loading,
      customRegisters,
      settingsData,
      getCustomRegisters,
      deleteCustomRegisters
    };
  }, [loading, customRegisters,settingsData,getCustomRegisters,deleteCustomRegisters]);

  return values;
};
