import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import BreadCrumbs from "../../components/BreadCrumbs";
import { useUserAuth } from "../../provider/AuthProvider";
import { useSettings } from "../../hooks/useSettings";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

interface Field {
  id: string;
  name: string;
}

interface FormValues {
  fields: Field[];
}

const SettingsLayout = () => {
  const theme = useTheme();
  const { user } = useUserAuth();
  const {
    customRegisters,
    deleteCustomRegisters,
    settingsData,
    getCustomRegisters,
  } = useSettings();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      fields: [{ id: "", name: "" }],
    },
  });
  const [fields, setFields] = useState<Field[]>([{ id: "", name: "" }]);
  const [loading, setLoading] = useState<number | null>(null);
  const { lastMessage } = useSocketContext();

  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = lastMessage.data;
      const msg = JSON.parse(message);
      if (
        msg.topic === "active_call" &&
        user?.id === msg.hospitalId &&
        msg.from === "doctor"
      ) {
        // reload the page
        // alert(`Dr ${msg.doctorName} has ${msg.action} the ${msg.message}`);
        // if (typeof window !== "undefined") {
        //   window.location.reload();
        // }
        //const audio = new Audio(require("../../assets/notification.wav"));
        //audio.play();
      }
    }

    getCustomRegisters();
  }, []);

  React.useEffect(() => {
    if (settingsData) {
      setFields(settingsData);
    }
  }, [settingsData]);

  const addField = () => {
    const newField = { id: "", name: "" };
    setFields([...fields, newField]);
    setValue("fields", [...fields, newField]);
  };

  const removeField = async (selectedfield: Field) => {
    try {
      // call api
      deleteCustomRegisters(selectedfield.id);
    } catch (error) {
      console.log(error);
    }
  };

  const saveField = async (index: number) => {
    try {
      setLoading(index);
      const fieldToSave = watch(`fields.${index}`);
      // call api
      const payloadText = fieldToSave["name"];
      const payload = { label: payloadText };
      customRegisters(payload);
      setLoading(null);
    } catch (error) {
      setLoading(null);
    }
  };

  return (
    <Stack direction="column" spacing={3}>
      <BreadCrumbs
        data={["Form", "Active calls", "Settings"]}
        title="Settings"
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Patient Details Form</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={addField}
        >
          Add Tab
        </Button>
      </Stack>
      <form onSubmit={handleSubmit(() => saveField(-1))}>
        {fields.map((field: any, index) => (
          <Stack
            key={field.id || index}
            direction="row"
            spacing={3}
            alignItems="center"
            mb={2}
          >
            <FormControl
              sx={{ width: "30%" }}
              error={!!errors.fields?.[index]?.name}
            >
              <InputLabel
                htmlFor={`outlined-adornment-name${field.id || index}`}
              >
                Document type
              </InputLabel>
              <Controller
                name={`fields.${index || field.id}.name`}
                defaultValue={field?.name || ""}
                control={control}
                rules={{ required: "Field is required" }}
                render={({ field: controllerField }) => (
                  <OutlinedInput
                    {...controllerField}
                    id={`outlined-adornment-name${field.name}`}
                    label="Document type"
                    value={controllerField.value || ""}
                    onChange={(e) => {
                      controllerField.onChange(e);
                      const updatedFields = [...fields];
                      updatedFields[index].name = e.target.value;
                      setFields(updatedFields);
                      setValue("fields", updatedFields);
                    }}
                  />
                )}
              />
              {errors.fields?.[index]?.name && (
                <Typography variant="caption" color="error">
                  {errors.fields[index]?.name?.message}
                </Typography>
              )}
            </FormControl>
            <Button
              variant="contained"
              startIcon={
                loading === index ? (
                  <CircularProgress size={12} />
                ) : (
                  <SaveAltIcon />
                )
              }
              onClick={(e) => {
                e.preventDefault();
                saveField(index);
              }}
            >
              Save
            </Button>
            <IconButton
              sx={{
                background: theme.palette.primary.main,
                borderRadius: "8px",
                minWidth: "60px",
              }}
              onClick={() => removeField(field)}
            >
              <DeleteIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
          </Stack>
        ))}
      </form>
    </Stack>
  );
};

export default SettingsLayout;
