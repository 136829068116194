import React, { useEffect } from 'react'
import PaymentLayout from '../../layout/hospital/PaymentLayout'
import { useUserAuth } from '../../provider/AuthProvider';


// const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || "wss://etiicos.in/websocket");

const PaymentPage = () => {


  const { user } = useUserAuth();
  // const [ws, setWs] = React.useState<WebSocket | null>(null);
  // useEffect(() => {
  //   socket.onopen = () => {
  //     console.log("Connected to WebSocket server");
  //     setWs(socket);
  //   };

  //   socket.onmessage = (event: any) => {
  //     const message = event.data;
  //     const msg = JSON.parse(message);
  //     console.info(msg)

  //     console.info(user)

  //     if(user?.id === msg.hospitalId && msg.callBy === 'doctor'){
  //       alert(`Dr ${msg.doctorName} has ${msg.action} the ${msg.message}`)
  //     }
  //   };
  // })

  return (
    <PaymentLayout />
  )
}

export default PaymentPage