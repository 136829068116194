import React from 'react';
import { Avatar, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { HospitalProps } from '../provider/AuthProvider';

interface HospitalDetailCardProps {
    data: HospitalProps;
    onClickRemove: (item: HospitalProps) => void;
}

const HospitalDetailCard = ({ data, onClickRemove }: HospitalDetailCardProps) => {
  const theme = useTheme(); 
  return (
    <Stack marginBottom={2} direction="row" justifyContent="space-between" alignItems="center" padding="16px" border={`1px solid ${theme.palette.border}`} borderRadius="8px" >
        <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar sx={{ bgcolor: theme.palette.border, color: theme.palette.text.primary }}>{data?.name.charAt(0)}</Avatar>
            <Box>
                <Typography variant='subtitle1'>
                    {data.name}
                </Typography>
                <Typography color={theme.palette.black?.[700]} variant='body2'>
                    {data?.city}
                </Typography>
            </Box>
        </Stack>
        <IconButton onClick={() => onClickRemove(data)} sx={{ background: theme.palette.primary.main, borderRadius: '8px' }}>
            <DeleteIcon sx={{ color: theme.palette.text.secondary }} />
        </IconButton>
    </Stack>
  )
}

export default HospitalDetailCard