import React, { useCallback, useEffect, useState } from "react";
import ActiveTokenLayout from "../../layout/hospital/ActiveTokenLayout";
import { TokenCountResponse, useToken } from "../../hooks/useToken";
import { useUserAuth } from "../../provider/AuthProvider";

const ActiveTokenPage = () => {
  const { getTokens, getTokenCount, tokenList, tokenCount } = useToken();
  const [selectedToken, setSelectedToken] = useState<TokenCountResponse | null>(
    null
  );
  const { user } = useUserAuth();

  useEffect(() => {
    getTokenCount();
  }, []);

  const onClickDepartment = useCallback(
    async (e: TokenCountResponse) => {
      try {
        if (e?.id) {
          setSelectedToken(e);
          await getTokens(e?.id, null, user?.id, 1000);
        }
      } catch (error) {
        setSelectedToken(null);
      }
    },
    [getTokens]
  );

  const onClickBack = useCallback(() => {
    setSelectedToken(null);
  }, []);
  return (
    <ActiveTokenLayout
      refetch={() => getTokens(selectedToken?.id, null, user?.id, 1000)}
      onClickBack={onClickBack}
      selectedToken={selectedToken}
      tokenCount={tokenCount}
      rows={tokenList}
      onClickDepartment={onClickDepartment}
    />
  );
};

export default ActiveTokenPage;
