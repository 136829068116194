import React, { useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import DataGridContainer from "../../components/DataGrid";
import Clock from "../../components/Clock";
import { useToken } from "../../hooks/useToken";
import { useUserAuth } from "../../provider/AuthProvider";
import { Box } from "@mui/material";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";
import { Howl } from "howler";

// const socket = new WebSocket(
//   process.env.REACT_APP_WEBSOCKET_URL || "wss://etiicos.in/websocket"
// );

const TokenSystemHomePage = () => {
  const { getTokenSystemList, tokenSystemList } = useToken();
  const { user } = useUserAuth();
  const { connectionStatus, lastMessage } = useSocketContext();

  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = lastMessage.data;
      const msg = JSON.parse(message);
      if (msg.topic === "token_call" && user?.id === msg.hospitalId) {
        if (msg.action === "start") {
          Howler.autoUnlock = true;
          var sound = new Howl({
            src: ["/audio.mp3"],
            onplayerror: function () {
              sound.once("unlock", function () {
                sound.play();
              });
            },
          });
          sound.play();
          if ("speechSynthesis" in window) {
            const utterance = new SpeechSynthesisUtterance(
              `Patient Number ${msg.tokenNumber} for the department ${msg.department} Please go to room number ${msg.room} `
            );
            window.speechSynthesis.speak(utterance);
          } else {
            alert("Text-to-speech not supported.");
          }
          getTokenSystemList();
        }
        if (msg.action === "end") {
          // reload the api
          getTokenSystemList();
        }
      }
    }
  }, [connectionStatus, lastMessage]);

  useEffect(() => {
    getTokenSystemList();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "tokenNo",
      headerName: "Token No",
      flex: 1,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "patientId",
      headerName: "Patient ID",
      flex: 1,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "doctorName",
      headerName: "Doctor Name",
      flex: 1,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "roomNo",
      headerName: "Room No",
      flex: 1,
      cellClassName: "super-app-theme--cell",
    },
  ];
  return (
    <div style={{ padding: "32px" }}>
      <Clock />
      <Box
        sx={{
          "& .super-app-theme--cell": {
            backgroundColor: "lightblue",
            color: "#1a3e72",
            fontWeight: "600",
          },
        }}
      >
        <DataGridContainer columns={columns} rows={tokenSystemList} />
      </Box>
    </div>
  );
};

export default TokenSystemHomePage;
