import React, {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

const SOCKET_URL: string = process.env.REACT_APP_WEBSOCKET_URL || '';

interface ISocketContext {
  sendMessage: (message: string) => void;
  lastMessage: MessageEvent | null;
  readyState: ReadyState;
  getWebSocket: () => WebSocket | null;
  connectionStatus: string;
}

const SocketContext = createContext<ISocketContext | null>(null);

export const useSocketContext = (): ISocketContext =>
  useContext(SocketContext) as ISocketContext;

interface SocketProviderProps {
  children: ReactNode;
}

const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
  const [queryParams, setQueryParams] = useState<null | string>(null);
  const socketUrlWithQuery = queryParams
    ? `${SOCKET_URL}?${queryParams}`
    : SOCKET_URL;

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketUrlWithQuery,
    {
      share: true,
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      heartbeat: {
        message: "ping",
        returnMessage: "pong",
        timeout: 60000, // 1 minute, if no response is received, the connection will be closed
        interval: 25000, // every 25 seconds, a ping message will be sent
      },
    }
  );

  const readyStateString: any = {
    [ReadyState.CONNECTING]: "CONNECTING",
    [ReadyState.OPEN]: "OPEN",
    [ReadyState.CLOSING]: "CLOSING",
    [ReadyState.CLOSED]: "CLOSED",
  };

  useEffect(() => {
    console.log(readyStateString[readyState]);
  }, [readyState]);

  return (
    <SocketContext.Provider
      value={
        {
          sendMessage,
          lastMessage,
          readyState,
          getWebSocket,
          connectionStatus: readyStateString[readyState],
          setQueryParams,
          queryParams,
        } as any
      }
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
