import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import AdminDashboardLayout from '../../layout/admin/AdminDashboardLayout'
import { useAdmin } from '../../hooks/useAdmin';

const AdminDashboardPage = () => {
  const { getHospitalList, getCount, hospitalList, count } = useAdmin();
  useEffect(() => {
    getHospitalList();
    getCount();
  }, []);
  return (
    <Box>
      <AdminDashboardLayout hospitalList={hospitalList} count={count} />
    </Box>
  )
}

export default AdminDashboardPage;
