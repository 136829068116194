import React, { useState } from 'react'
import { Box, Button, Typography, CircularProgress } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import BreadCrumbs from '../../components/BreadCrumbs'
import DataGridContainer from '../../components/DataGrid';
import { DoctorResponseData } from '../../hooks/useAdmin';

interface AdminDoctorLayoutProps {
  doctorList: DoctorResponseData | null;
  updateDoctorStatus: (id: string, status: string) => Promise<void>;
}

const AdminDoctorLayout = ({ doctorList, updateDoctorStatus }: AdminDoctorLayoutProps) => {
  const [selectedId, setSelectedId] = useState('');
  const updateStatus = async (id: string, status: string) => {
    try {
      setSelectedId(id);
      await updateDoctorStatus(id, status);
      setSelectedId('');
    } catch (error) {
      setSelectedId('');
    }
  }
  const columns: GridColDef[] = [
    { field: '_id', headerName: 'Doctor ID', minWidth: 200 },
    {
      field: 'doctorName',
      headerName: 'Doctor name',
      minWidth: 250,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 300,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150,
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 160,
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 160,
    },
    {
      field: 'isApproved',
      headerName: 'Status',
      width: 160,
      renderCell: (row) => (
        <>{(row?.row?.isApproved as string)?.toUpperCase()}</>
      )
    },
    {
      field: 'updateStatus',
      headerName: 'Update status',
      renderCell: (row) => (
        <Button style={{backgroundColor : (row?.row?.isApproved === 'rejected') || (row?.row?.isApproved === 'pending') ?"green":"red"}} startIcon={row?.row?._id === selectedId && <CircularProgress size={12} /> } onClick={() => updateStatus(row?.row?._id, (row?.row?.isApproved === 'pending' || row?.row?.isApproved === 'rejected') ? 'approved' : 'rejected')} variant='outlined'>
          {(row?.row?.isApproved === 'pending' || row?.row?.isApproved === 'rejected') ? 'Approve' : 'Reject'}
        </Button>
      ),
      width: 160,
    },
  ];
  return (
    <Box>
      <BreadCrumbs data={['Home', 'Tables', 'OPD Doctor Request']} title="OPD Doctor Request" />
      <Typography marginTop="16px" marginBottom="16px" variant='h4'>Total Doctor Request</Typography>
      { doctorList?.doctors && doctorList?.doctors?.length > 0 && (
        <DataGridContainer getRowId={(row) => row._id} columns={columns} rows={doctorList?.doctors} />
      ) }
    </Box>
  )
}

export default AdminDoctorLayout