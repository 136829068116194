import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, FormControl, InputLabel, OutlinedInput, Stack, Typography, useTheme,IconButton } from '@mui/material'
import BreadCrumbs from '../../components/BreadCrumbs';
import { useForm, Controller } from 'react-hook-form';
import { useDepartment } from '../../hooks/useDepartments';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

interface Field {
    id: string;
    name: string;
}

interface FormValues {
    fields: Field[];
}

const AdminSettingsLayout = () => {

    const theme = useTheme();
    const { departmentList, createDepartment, getDepartment, deleteDepartmentById,savePlan,getPlan,planList } = useDepartment();
    const { control, formState: { errors }, handleSubmit, setValue, watch } = useForm<FormValues>({
        defaultValues: {
          fields: [{ id: '', name: '' }]
        }
      });
    const [plan,setPlan] = useState<string>("")


    useEffect(() => {
        if (departmentList) {
          setValue('fields', departmentList);
          if(planList){
            setPlan(planList)
          }
        }
      }, [departmentList]);
    
      useEffect(() => {
          getDepartment()
          getPlan()
      }, []);
    
      const addField = () => {
        const newField = { id: '', name: '' };
        setValue('fields', [...watch('fields'), newField]);
      };
    
      const removeField = async (id: string) => {
        try {
          await deleteDepartmentById(id);
          await getDepartment()
        } catch (error) {
            console.log(error)
        }
      };
    
      const saveField = async (index: number) => {
        try {
          const fieldToSave = watch(`fields.${index}`);
          await createDepartment({ name: fieldToSave?.name });
        } catch (error) {
            console.log(error)
        }
      };

      const onPlanSaveHandler = async () => {
        try{
            await savePlan({plan:plan})
        }catch(err){
            console.log(err)
        }
      }

  return (
    <Box>
      <BreadCrumbs data={['Home', 'Departments & Price']} title="Department" />
      <Box marginTop="1.5rem">
            <Box marginBottom="2rem">
                <Typography marginBottom="8px" variant='body1'>Subscription Plan</Typography>
                <Stack direction="row" spacing={2}>
                    <FormControl>
                        <InputLabel htmlFor="outlined-adornment-subscriptionPrice">Subscription Plan</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-subscriptionPrice"
                            label="Subscription Price"
                            onChange={(e)=>setPlan(e.target.value)}
                            value={plan}
                        />
                    </FormControl>
                    <Button variant='contained' onClick={onPlanSaveHandler}>Save</Button>
                </Stack>
            </Box>
            <Divider />
            <Box marginTop="2rem">
                <Stack direction="row" justifyContent="space-between">
                <Typography marginBottom="8px" variant='body1'>Add Departments</Typography>
                <Button variant='contained' startIcon={<AddCircleOutlineIcon />} onClick={addField}>Add Tab</Button>
                </Stack>
                <form onSubmit={handleSubmit(() => saveField(-1))}>
                {watch('fields')?.map((field, index) => (
          <Stack key={index} direction="row" spacing={3} alignItems="center" mb={2}>
            <FormControl sx={{ width: '30%' }} error={!!errors.fields?.[index]?.name}>
              <InputLabel htmlFor={`outlined-adornment-name${field.id}`}>Add Departments</InputLabel>
              <Controller
                name={`fields.${index}.name`}
                control={control}
                defaultValue={field.name}
                rules={{ required: 'Field is required' }}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id={`outlined-adornment-name${field.name}`}
                    label="Add Departments"
                    onChange={(e) => {
                      field.onChange(e);
                      const updatedFields = [...watch('fields')];
                      updatedFields[index].name = e.target.value;
                      setValue('fields', updatedFields);
                    }}
                  />
                )}
              />
              {errors.fields?.[index]?.name && (
                <Typography variant="caption" color="error">
                  {errors.fields[index]?.name?.message}
                </Typography>
              )}
            </FormControl>
            <Button
              variant='contained'
              onClick={(e) => {
                e.preventDefault();
                saveField(index);
              }}
            >
              Save
            </Button>
            <IconButton
              sx={{ background: theme.palette.primary.main, borderRadius: '8px', minWidth: '60px' }}
              onClick={() => removeField(field.id)}
            >
              <DeleteIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
          </Stack>
        ))}
                </form>
            </Box>
      </Box>
    </Box>
  )
}

export default AdminSettingsLayout;
