import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { Images } from '../assets/images'
import LoginTab from '../components/LoginTab'

const LandingLayout = () => {
  return (
    <Grid container spacing={4} sx={{ padding: '60px' }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <Stack direction="column" spacing={3}>
                <img alt='Logo' src={Images.logoLight} style={{ maxHeight: '60px', maxWidth: '150px' }} />
                <Typography lineHeight={1.5} variant='h3'>Welcome to etiicos <br />The Smart Patient Care System </Typography>
                <LoginTab />
            </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <img alt='Banner' src={Images.banner} style={{ maxHeight: '60vh' }} />
        </Grid>
    </Grid>
  )
}

export default LandingLayout