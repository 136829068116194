import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Toolbar } from "@mui/material";

const AppLayout = () => {
  return (
    <>
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, ml: '280px', paddingTop: '34px' }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
};

export default AppLayout;
