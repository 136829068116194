import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import AdminHospitalLayout from '../../layout/admin/AdminHospitalLayout'
import { useAdmin } from '../../hooks/useAdmin';

const AdminHospitalPage = () => {
  const { loading, hospitalList, getHospitalList, updateHospitalStatus } = useAdmin();
  useEffect(() => {
    getHospitalList();
  }, []);
  return (
    <Box>
        <AdminHospitalLayout hospitalList={hospitalList} updateHospitalStatus={updateHospitalStatus} />
    </Box>
  )
}

export default AdminHospitalPage;
