import React, { useEffect } from 'react'
import TokenLogLayout from '../../layout/hospital/TokenLogLayout'
import { useToken } from '../../hooks/useToken';
import { useUserAuth } from '../../provider/AuthProvider';

const TokenLogsPage = () => {
  const { getTokens, tokenList } = useToken();
  const { user } = useUserAuth();

  useEffect(() => {
    getTokens(undefined,undefined,user?.id)
  }, []);
  return (
    <TokenLogLayout tokenList={tokenList} />
  )
}

export default TokenLogsPage