import React, { useState, useEffect, useCallback } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import Moment from 'react-moment';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { setUserSessionToken } from '../config/AxiosClient';
import { useUserAuth } from '../provider/AuthProvider';

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();
  const { setUser } = useUserAuth();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const onClickLogout = useCallback(() => {
    localStorage.clear();
    setUserSessionToken(null);
    setUser(null);
    navigate('/auth');
  }, [navigate, setUser]);

  return (
    <Stack marginBottom={2} direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
      <Typography>Date & Time:</Typography>
      <Stack direction="row" alignItems='center' spacing={1}>
        <Moment format="YYYY-MM-DD hh:mm:ss A z">{currentTime}</Moment>
        <IconButton onClick={onClickLogout}>
          <LogoutIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Clock;
