import React, { useCallback, useEffect } from 'react'
import ModifyRoomLayout from '../../layout/hospital/ModifyRoomLayout'
import { ItemDetails, useDoctorList } from '../../hooks/useDoctorList';

const ModifyRoomPage = () => {
  const { getDoctorList, doctorList, saveRoomForDoctor, loading } = useDoctorList();

  const saveRoom = useCallback(async (id: string, room: number) => {
    await saveRoomForDoctor(id, room);
  }, [saveRoomForDoctor])

  useEffect(() => {
    getDoctorList();
  }, []);

  return (
    <ModifyRoomLayout saveRoom={saveRoom} isLoading={loading} doctorList={doctorList} />
  )
}

export default ModifyRoomPage