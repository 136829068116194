import React from "react";
import DoctorScheduleLayout from "./DoctorScheduleLayout";
import { useDoctorSchedule } from "../../hooks/useDoctorSchedule";

const DoctorSchedulePage = () => {
    const {  loading,
        doctorSchedules,
        getDoctorSchedules } = useDoctorSchedule();

        React.useEffect(()=>{
            getDoctorSchedules()
        },[])

    return <>
    <DoctorScheduleLayout doctorSchedules={doctorSchedules}/>
    </>
}


export default DoctorSchedulePage