import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PhoneDisabledOutlinedIcon from "@mui/icons-material/PhoneDisabledOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import { CallRecord } from "../../hooks/useCustomCalls";
import { useFees } from "../../hooks/useFees";
import { useUserAuth } from "../../provider/AuthProvider";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

interface DoctorDashboardLayoutProps {
  callLogs: CallRecord[];
  startCallById: (id: string) => Promise<void>;
  endCallById: (id: string) => Promise<void>;
  getCallLogs: (id: string) => Promise<void>;
}

const DoctorDashboardLayout = ({
  callLogs,
  startCallById,
  endCallById,
  getCallLogs
}: DoctorDashboardLayoutProps) => {
  const theme = useTheme();
  const { user }: any = useUserAuth();
  const { feeData, loading, getFees, saveFees } = useFees();
  const [fees, setFees] = useState(feeData ?? "");
  const { connectionStatus ,sendMessage, lastMessage } = useSocketContext();

  useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      try {
        const message = lastMessage.data;
        const msg = JSON.parse(message);
        // TODO: Need to add the hospital ID
       
        if(msg.topic === 'active_call' && msg.action === 'end' && msg.from === 'hospital'){
          ( async () => await getCallLogs(user?.activeHospital?.id || user?.hospital?.[0]?.id))()
          
      }
      } catch (err) {
        console.log(err);
      }
    }
  }, [connectionStatus, lastMessage]);

  useEffect(() => {
    if (feeData) {
      setFees(feeData);
    }
  }, [feeData]);

  useEffect(() => {
    if (user?.id && user?.hospital) {
      getFees(user?.id, user?.activeHospital?.id || user?.hospital?.[0]?.id);
    }
  }, [user]);

  const onClickSaveFees = useCallback(async () => {
    await saveFees(user?.activeHospital?.id || user?.hospital?.[0]?.id, fees);
  }, [saveFees, fees, user]);

  return (
    <Stack direction="column" spacing={3}>
      <BreadCrumbs
        data={["Home", "Doctor", "Dashboard"]}
        title="Doctor Dashboard"
      />
      <Typography variant="h4">Doctor Name: {user?.userName}</Typography>
      <Stack direction="row" spacing={2}>
        <FormControl>
          <InputLabel htmlFor="outlined-adornment-fees">Fees</InputLabel>
          <OutlinedInput
            value={fees}
            onChange={(e) => setFees(e.target.value)}
            id="outlined-adornment-fees"
            label="Fees"
          />
        </FormControl>
        <Button
          disabled={loading}
          startIcon={loading && <CircularProgress size={16} />}
          onClick={onClickSaveFees}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
      <Box>
        <Typography variant="subtitle2" marginBottom="12px">
          You can add custom calls in the settings.
        </Typography>
        {callLogs?.map((item) => (
          <Stack
            marginBottom={2}
            key={item.id}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            padding="16px"
            border={`1px solid ${theme.palette.border}`}
            borderRadius="8px"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <InfoOutlinedIcon />
              <Typography variant="body1">{item?.message}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                disabled={!item.isActive}
                onClick={() => {
                  sendMessage(
                    JSON.stringify({
                      topic: "active_call",
                      callId: item.id,
                      from: "doctor",
                      action: "end",
                    })
                  );
                  endCallById(item.id);
                }}
                variant="contained"
                startIcon={<PhoneDisabledOutlinedIcon />}
                style={{ backgroundColor: "red" }}
              >
                End
              </Button>
              <Button
                disabled={item.isActive}
                onClick={() => {
                  console.info("WEBSOCKET PAYLOAD");
                  sendMessage(
                    JSON.stringify({
                      topic: "active_call",
                      callId: item.id,
                      from: "doctor",
                      action: "start",
                    })
                  );
                  startCallById(item.id);
                }}
                variant="contained"
                startIcon={<PhoneEnabledOutlinedIcon />}
              >
                Call
              </Button>
            </Stack>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};

export default DoctorDashboardLayout;
