import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { Token } from "../constants/EndPoints";
  
export interface CallRecord {
    id: string;
    message: string;
    hospitalId: string;
    doctorId: string;
    isActive: boolean;
}

export const useTokenCall = () => {
    const [loading, setLoading] = useState(false);

    const startCall = useCallback(async (id: string) => {
        try {
            setLoading(true);
            await getHttpClient(`${Token.token}/${id}/startCall`, 'POST');
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const endCall = useCallback(async (id: string) => {
        try {
            setLoading(true);
            await getHttpClient(`${Token.token}/${id}/endCall`, 'POST');
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const values = useMemo(() => {
        return {
            loading,
            startCall,
            endCall,
        }
    }, [loading, startCall, endCall]);

    return values;
};