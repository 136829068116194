import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { Token } from "../constants/EndPoints";

interface Patient {
  _id: string;
  name: string;
  gender: string;
  phone: string;
  age: number;
  createdAt: string;
  updatedAt: string;
  id: string;
  patientId: string;
}

interface Hospital {
  _id: string;
  email: string;
  password: string;
  confirmation_token: string | null;
  createdAt: string;
  updatedAt: string;
  address: string;
  city: string;
  department: string[];
  isOnboard: boolean;
  name: string;
  phone: string;
  state: string;
  isActive: boolean;
  isApproved: string;
  approvedBy: string;
  confirmedAt: string;
  id: string;
}

interface Department {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}

interface Appointment {
  _id: string;
  patientId: Patient;
  hospitalId: Hospital;
  departmentId: Department;
  Date: string;
  tokenNumber: number;
  roomNo: string;
  startTime: string | null;
  endTime: string | null;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  slot: {
    start_time: string;
    end_time: string;
    slotId: string;
  };
  doctorId: {
    _id: string;
    name: string;
    email: string;
    id: string;
  };
  id: string;
  meta: Record<string, string>;
}

export interface AppointmentDetails {
  id: string;
  name: string;
  tokenNo: string;
  roomNo: string;
  department: string;
  date: string;
  startTime: string;
  endTime: string;
}

export interface TokenResponse {
  id: string;
  name: string;
  tokenNo: string;
  roomNo: string;
  department: string;
  date: string;
  startTime: string;
  endTime: string;
  createdBy: string;
  tokenId: string;
}

export interface TokenCountResponse {
  id: string;
  name: string;
  count: number;
}

export const useToken = () => {
  const [loading, setLoading] = useState(false);
  const [tokenList, setTokenList] = useState<TokenResponse[]>([]);
  const [tokenSystemList, setTokenSystemList] = useState<TokenResponse[]>([]);
  const [tokenCount, setTokenCount] = useState<TokenCountResponse[]>([]);

  const getTokens = useCallback(async (departmentId?: string,endTime?:any,hospitalId?:string|number, limit?:number) => {
    try {
      const params: any = {};
      if (departmentId) params.departmentId = departmentId;
      if(endTime === null) params.endTime = "null";
      if(hospitalId) params.hospitalId = hospitalId
      if(limit) params.limit = limit 
      setLoading(true);
      const response = await getHttpClient(
        Token.token,
        "GET",
        undefined,
        Object.keys(params).length ? params : undefined
      );
      const formattedResponse = response?.tokens?.map((item: Appointment) => ({
        tokenId: item?.id,
        id: item?.patientId?._id,
        name: item?.patientId?.name,
        tokenNo: item?.tokenNumber,
        roomNo: item?.roomNo,
        department: item?.departmentId?.name,
        date: item?.Date,
        startTime: item?.startTime,
        endTime: item?.endTime,
        createdBy: item.createdBy,
        departmentId: item?.departmentId,
        patientId : item?.patientId,
        slot: item?.slot,
        doctorId: item?.doctorId,
        meta: item?.meta,
      }))
      setTokenList(formattedResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTokenList([]);
    }
  }, []);

  const getTokenSystemList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getHttpClient(
        Token.tokenSystem,
        "GET"
      );
      const formattedResponse = response?.token?.map((item: Appointment) => ({
        id: item?.patientId?._id,
        patientId: item?.patientId?.patientId,
        name: item?.patientId?.name,
        tokenNo: item?.tokenNumber,
        roomNo: item?.roomNo,
        department: item?.departmentId?.name,
        date: item?.Date,
        startTime: item?.startTime,
        endTime: item?.endTime,
        createdBy: item.createdBy,
        slot: item?.slot,
        doctorName: item?.doctorId?.name,
      }));
      setTokenSystemList(formattedResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const getTokenCount = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getHttpClient(
        Token.activecount,
        "GET"
      );
      setTokenCount(response?.response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const values = useMemo(() => {
    return {
      loading,
      tokenList,
      tokenSystemList,
      tokenCount,
      getTokenCount,
      getTokens,
      getTokenSystemList,
    };
  }, [loading, tokenList, tokenSystemList, tokenCount, getTokenCount, getTokens, getTokenSystemList]);

  return values;
};
