import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { DoctorSchedule } from "../constants/EndPoints";

export interface DoctorScheduleResponse {
    hospital: string;
    perpatientTime: number;
    doctor: string;
    date: string;
    slotAvailability: {}[];
    createdAt: string;
    updatedAt: string;
    id: string;
    day: string;
    date_string: string;
}


export const useDoctorSchedule = () => {
  const [loading, setLoading] = useState(false);
  const [doctorSchedules, setDoctorSchedules] = useState<DoctorScheduleResponse[]>([])
  const [viewDoctorScheduleData,setViewDoctorScheduleData] = useState<any[]>([])

  const createDoctorSchedule = useCallback(
    async (payload: any) => {
      try {
        setLoading(true);
        await getHttpClient(DoctorSchedule.doctorSchedule, "POST", payload);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  const addTimeSlot = useCallback(  async (id:string,payload: any) => {
    try {
      setLoading(true);
      await getHttpClient(`${DoctorSchedule.doctorSchedule}/${id}/addSlot`, "POST", payload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  },
  [])

  const deletedoctorSchedule = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        await getHttpClient(`${DoctorSchedule.doctorSchedule}/${id}`, "DELETE");
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  const editTimeSlot =  useCallback(
    async (docId: string,slotId:string,payload:any) => {
      try {
        setLoading(true);
        await getHttpClient(`${DoctorSchedule.doctorSchedule}/${docId}/slot/${slotId}`, "PUT", payload);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  const deleteTimeSlot = useCallback(async (docId: string,slotId:string) => {
    try {
      setLoading(true);
      const response = await getHttpClient(
       `${DoctorSchedule.doctorSchedule}/${docId}/deleteSlot/${slotId}` ,
        "PUT"
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);


  const viewDoctorSchedule =  useCallback(async (payload:any) => {
    try {
      setLoading(true);
      const response = await getHttpClient(
       `doctor/${payload}/doctor-schedule` ,
        "GET"
      );
      const formattedResponse = response?.data?.map((item:any)=>({
        hospital: item.hospital,
        perpatientTime: item.perpatientTime,
        doctor: item.doctor,
        date: item.date,
        slotAvailability: item.slotAvailability,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        id: item.id,
        doc_id: item.doctor_id,
        day: item.day,
        date_string: item.date_string,
      }))
      setViewDoctorScheduleData(formattedResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDoctorSchedules([]);
    }
  }, []);


  const getDoctorSchedules = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getHttpClient(
        DoctorSchedule.doctorSchedule,
        "GET"
      );
      const formattedResponse = response?.data?.map((item:any)=>({
        hospital: item.hospital,
        perpatientTime: item.perpatientTime,
        doctor: item.doctor,
        department: item.depeartment,
        date: item.date,
        slotAvailability: item.slotAvailability,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        doc_id: item.doctor_id,
        id: item.id,
        day: item.day,
        date_string: item.date_string,
        scheduleId: item.scheduleId
      }))
      setDoctorSchedules(formattedResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDoctorSchedules([]);
    }
  }, []);

  const values = useMemo(() => {
    return {
      loading,
      doctorSchedules,
      getDoctorSchedules,
      createDoctorSchedule,
      viewDoctorSchedule,
      viewDoctorScheduleData,
      editTimeSlot,
      deleteTimeSlot,
      deletedoctorSchedule,
      addTimeSlot
    };
  }, [loading, doctorSchedules,addTimeSlot,deletedoctorSchedule,editTimeSlot,deleteTimeSlot,getDoctorSchedules,createDoctorSchedule,viewDoctorSchedule,viewDoctorScheduleData]);

  return values;
};
