import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DataGridContainer from "../../components/DataGrid";
import BreadCrumbs from "../../components/BreadCrumbs";
import { AppointmentDetails } from "../../hooks/useToken";
import { useUserAuth } from "../../provider/AuthProvider";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

const columns: GridColDef[] = [
  {
    field: "patientId",
    headerName: "Patient ID",
    width: 250,
    //Reduce "ID" column to 8 bit.
    renderCell: (row) => {
      return <>{String(row?.row?.patientId?.patientId)}</>;
    },
  },
  {
    field: "name",
    headerName: "Patient Name",
    width: 250,
  },
  {
    field: "doctorname",
    headerName: "Doctor Name",
    width: 250,
    renderCell: (row) => {
      return <>{String(row?.row?.doctorId?.name)}</>;
    },
  },
  {
    field: "tokenNo",
    headerName: "Token No",
    width: 150,
  },
  {
    field: "roomNo",
    headerName: "Room No",
    width: 150,
  },
  {
    field: "department",
    headerName: "Department",
    width: 150,
  },
  {
    field: "date",
    headerName: "Date",
    width: 200,
    renderCell: (row) => {
      let convertedStartTime: any = new Date(row.row.date);
      let output = convertedStartTime.toLocaleDateString("en-us", {
        hour: undefined,
        minute: undefined,
        hour12: false,
      });
      return <>{output}</>;
    },
  },
  {
    field: "startTime",
    headerName: "Start time",
    width: 200,
    renderCell: (row) => {
      let convertedStartTime: any = new Date(row.row.startTime);
      let output = "-";
      if (row.row.startTime) {
        output = convertedStartTime.toLocaleTimeString("en-us", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
      }

      return <>{output}</>;
    },
  },
  {
    field: "endTime",
    headerName: "End time",
    width: 200,
    renderCell: (row) => {
      let convertedendTime: any = new Date(row.row.endTime);
      let output = "-";
      if (row.row.endTime) {
        output = convertedendTime.toLocaleTimeString("en-us", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
      }

      return <>{output}</>;
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 150,
    // adding duration logic
    renderCell: (row) => {
      let convertedEndTime: any = new Date(row.row.endTime).toLocaleTimeString(
        "en-us",
        { minute: "numeric", hour: "numeric", second: "numeric", hour12: false }
      );
      let convertedStartTime: any = new Date(
        row.row.startTime
      ).toLocaleTimeString("en-us", {
        minute: "numeric",
        hour: "numeric",
        second: "numeric",
        hour12: false,
      });
      let callDuration: any = convertedEndTime.split(":");
      let callDurationStartTime = convertedStartTime.split(":");
      let endTimeSecond = parseInt(callDuration[2]);
      let endTimeMinute = parseInt(callDuration[1]);
      let endTimeHour = parseInt(callDuration[0]);
      let startTimeSecond = parseInt(callDurationStartTime[2]);
      let startTimeMinute = parseInt(callDurationStartTime[1]);
      let startTimeHour = parseInt(callDurationStartTime[0]);

      let result = `${Math.abs(endTimeHour - startTimeHour)} H : ${Math.abs(
        endTimeMinute - startTimeMinute
      )}M : ${Math.abs(endTimeSecond - startTimeSecond)}S `;

      return <>{result}</>;
    },
  },
];

const TokenLogLayout = ({
  tokenList,
}: {
  tokenList: AppointmentDetails[];
  name?: string;
}) => {
  const { user } = useUserAuth();
  const { lastMessage } = useSocketContext();
  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = lastMessage.data;
      const msg = JSON.parse(message);
      if (
        msg.topic === "active_call" &&
        user?.id === msg.hospitalId &&
        msg.from === "doctor"
      ) {
        // reload the page
        // alert(`Dr ${msg.doctorName} has ${msg.action} the ${msg.message}`);
        //const audio = new Audio(require("../../assets/notification.wav"));
        //audio.play();
      }
    }
  }, []);

  return (
    <Box>
      <BreadCrumbs data={["Home", "Token", "Token Logs"]} title="Token Logs" />
      <div style={{ marginTop: "24px" }} />
      <DataGridContainer columns={columns} rows={tokenList} />
    </Box>
  );
};

export default TokenLogLayout;
