import { useCallback, useEffect } from 'react';
import { useCustomCall } from '../../hooks/useCustomCalls';
import DoctorDashboardLayout from '../../layout/doctor/DashboardLayout';
import { useUserAuth } from '../../provider/AuthProvider';
import { isValidJSON } from '../../utils/checkJson';
import { useSocketContext } from '../../provider/SocketProvider';

const DashboardPage = () => {
  const { callLogs, getCallLogs, startCall, endCall } = useCustomCall();
  const { user } = useUserAuth();
  const { connectionStatus , lastMessage } = useSocketContext();

  useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      try {
        const message = lastMessage.data;
        const msg = JSON.parse(message);
        // TODO: Need to add the hospital ID
        if(msg.topic === 'active_call' && msg.action === 'end' && msg.hospitalId === (user?.activeHospital?.id || user?.hospital?.[0]?.id) && msg.from === 'hospital'){
          getCallLogs(user?.activeHospital?.id || user?.hospital?.[0]?.id)
      }
      } catch (err) {
        console.log(err);
      }
    }
  }, [connectionStatus, lastMessage]);

  useEffect(() => {
    if (user?.hospital) {
      getCallLogs(user?.activeHospital?.id || user?.hospital?.[0]?.id)
    }
  }, [user]);

  const startCallById = useCallback(async (id: string) => {
    try {
      await startCall(user?.activeHospital?.id || user?.hospital?.[0]?.id, id);
      if (user?.hospital) {
        await getCallLogs(user?.activeHospital?.id || user?.hospital?.[0]?.id)
      }
    } catch (error) {
      
    }
  }, [startCall, getCallLogs, user]);

  const endCallById = useCallback(async (id: string) => {
    try {
      await endCall(id);
      if (user?.hospital) {
        await getCallLogs(user?.activeHospital?.id || user?.hospital?.[0]?.id)
      }
    } catch (error) {
      
    }
  }, [endCall, getCallLogs, user]);

  return (
    <DoctorDashboardLayout callLogs={callLogs} startCallById={startCallById} endCallById={endCallById} getCallLogs={getCallLogs} />
  )
}

export default DashboardPage