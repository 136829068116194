import React from 'react';
import AppProvider from './provider';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
    <AppProvider />
    <ToastContainer/>
    </>
  );
}

export default App;
