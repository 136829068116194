/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { Method } from 'axios';
import qs from 'qs';
import { isNil, get, isEqual, includes } from 'lodash';
import SessionService from '../services/SessionService';
import { Configuration } from '../constants/Configuration';
import { toast } from 'react-toastify';

axios.defaults.baseURL = Configuration.API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const AuthorizationKey = 'Authorization';

export const setUserSessionToken = (token?: string | null) => {
  if (!isNil(token)) {
    axios.defaults.headers.common[AuthorizationKey] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common[AuthorizationKey];
  }
};

export const getHttpClient = (
  path: string,
  method: Method,
  data: any | undefined = undefined,
  params: any | undefined = undefined,
): Promise<any> => {
  const query = !isNil(params)
    ? '?' + qs.stringify(params, { allowDots: true })
    : '';
  const urlPath = path + query;

  return asyncOperation(
    axios({
      method: method,
      url: urlPath,
      data: data,
    }),
  );
};

export const postMultiPart = async (
  path: string,
  method: string,
  data: any,
  params: any,
) => {
  const query =
    params !== null ? '?' + qs.stringify(params, { allowDots: true }) : '';

  const url = axios.defaults.baseURL + path + query;
  const rawResponse = await fetch(url, {
    method,
    body: data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: axios.defaults.headers.common[AuthorizationKey],
    } as any,
  });
  const jsonData = await rawResponse.json();
  const status = get(jsonData, 'status', get(jsonData, 'code'));
  const hasError = includes([500, 400, 404], status);
  if (hasError) {
    const message = get(jsonData, 'message');
    throw new ServerException(
      get(jsonData, 'error', message),
      status,
      jsonData,
    );
  }
  return jsonData;
};

const asyncOperation = async (request: Promise<any>) => {
  try {
    const response = await request;
    return response.data;
  } catch (error: any) {
    const data = get(error, 'response.data');

    const message = get(data, 'message', get(data, 'error'));
    notify(message)
    const status = get(data, 'status', error?.response?.status);
    if (isEqual(status, 401) || isEqual(status, 403)) {
      SessionService.unAuthenticated();
      localStorage.clear();
      window.location.href = '/auth';
      throw new ServerException(message, status, data);
    } else if (!isNil(message)) {
      throw new ServerException(message, status, data);
    } else {
      throw error;
    }
  }
};

class ServerException extends Error {
  status: number;
  response: any;
  constructor(message: string, status: number, response: any | null) {
    super(message);
    this.name = 'ServerException';
    this.status = status;
    this.response = response;
  }
}

export { ServerException };

const notify = (params:string = "something went wrong") => toast(params, {
  autoClose: 2000,
  style:{
    backgroundColor:"red",
    color:"white"
  }
});