import * as React from 'react';
import {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { StorageService } from '../services/StorageService';
import { Roles } from '../constants/Roles';
import { getHttpClient, setUserSessionToken } from '../config/AxiosClient';
import { CurrentUser } from '../constants/EndPoints';
import { stringCapitalize } from '../utils/StringCapitalize';
import { useLoading } from './AppLoadingProvider';

export type UserStatus = 'pending' | 'approved' | 'rejected';

export type HospitalProps = {
  id: string;
  name: string;
  city: string;
}

type AuthUser = {
  email: string;
  id: string;
  userName: string;
  authToken: string;
  role: Roles | null;
  isOnboard: boolean;
  isApproved: UserStatus;
  phone: string;
  about?: string;
  hospital?: HospitalProps[];
  department?: {
    id: string;
    name: string;
  }[];
  profileImage?: string;
  activeHospital?: any;
};

type AuthContextType = {
  user: AuthUser | null;
  loading: boolean;
  setUser: (user: AuthUser | null | ((prev: AuthUser | null) => AuthUser | null)) => void;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  setUser: () => {},
});

type AuthProviderProps = {
  children: JSX.Element;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(true);
  const { isAppLoading, setIsAppLoading } = useLoading();

  const getCurrentUserDetails = useCallback(async () => {
    try {
      setIsAppLoading(true);
      const authToken = StorageService.authToken.getValue();
      if (authToken) {
        setUserSessionToken(authToken);
        const response = await getHttpClient(CurrentUser.me, 'GET');
        const hospitalList = response?.data?.hospital?.map((item: any) => ({
          id: item?._id,
          name: item?.name,
          city: item?.city,
        }));
        const role: any = stringCapitalize(response?.role);
        const departmentList = response?.data?.department?.map((item: any) => ({
          id: item?._id,
          name: item?.name,
        }));
        setUser(prev => ({
          ...prev,
          role,
          id: response?.user?.id,
          userName: response?.user?.name,
          isApproved: response?.data?.isApproved,
          isOnboard: response?.data?.isOnboard,
          email: response?.data?.email,
          hospital: hospitalList,
          department: departmentList,
          authToken: authToken as string, // Ensuring authToken is a string
          phone: response?.data?.phone,
          about: response?.data?.about ?? '',
          profileImage: response?.data?.profileImage ?? '',
          activeHospital: response?.user?.activeHospital
        }));
      }
      setIsAppLoading(false);
    } catch (error) {
      setIsAppLoading(false);
    } finally {
      setLoading(false);
    }
  }, [setIsAppLoading]);

  useEffect(() => {
    if (isAppLoading) {
      getCurrentUserDetails();
    }
  }, [isAppLoading, getCurrentUserDetails]);

  const values = useMemo(
    () => ({
      user,
      loading,
      setUser,
    }),
    [user, loading]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
export const useUserAuth = () => {
  return useContext(AuthContext);
};
