import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDoctor } from "../../hooks/useDoctor";
import BreadCrumbs from "../../components/BreadCrumbs";
import { Button, CircularProgress } from "@mui/material";
import { useUserAuth } from "../../provider/AuthProvider";
import { useDepartment } from "../../hooks/useDepartments";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DepartmentLayout = () => {
  const { getAllDepartments, departments } = useDoctor();
  const { updateDepartment, loading } = useDepartment();
  const { user } = useUserAuth();
  const [selectedId, setSelectedId] = React.useState<string[]>(
    user?.department?.map((item) => item?.id) ?? []
  );

  const { lastMessage } = useSocketContext();

  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = lastMessage.data;
      const msg = JSON.parse(message);
      if (
        msg.topic === "active_call" &&
        user?.id === msg.hospitalId &&
        msg.from === "doctor"
      ) {
        // reload the page
        //const audio = new Audio(require("../../assets/notification.wav"));
        //audio.play();
      }
    }
  }, []);

  React.useEffect(() => {
    getAllDepartments();
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof selectedId>) => {
    const {
      target: { value },
    } = event;
    setSelectedId(typeof value === "string" ? value.split(",") : value);
  };

  const onClickSubmit = React.useCallback(async () => {
    await updateDepartment(selectedId);
  }, [selectedId, updateDepartment]);

  return (
    <Box>
      <BreadCrumbs
        data={["Home", "Token", "Department List"]}
        title="Department List"
      />
      <Typography sx={{ marginTop: "24px", marginBottom: "24px" }} variant="h4">
        Select Department
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="multiple-checkbox-label">Select Department</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedId}
          onChange={handleChange}
          input={<OutlinedInput label="Select Department" />}
          renderValue={(selected) =>
            departments
              ?.filter((item) => selected?.includes(item?.value))
              ?.map((li) => li.label)
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {departments?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={selectedId.indexOf(item.value) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        startIcon={loading && <CircularProgress size={16} />}
        sx={{ marginTop: "24px" }}
        variant="contained"
        onClick={onClickSubmit}
      >
        Submit
      </Button>
    </Box>
  );
};

export default DepartmentLayout;
