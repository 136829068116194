import React, { useEffect } from 'react'
import CallLogsLayout from '../../layout/hospital/CallLogsLayout'
import { useCallLogs } from '../../hooks/useCallLogs';
import { useUserAuth } from '../../provider/AuthProvider';
// const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || "wss://etiicos.in/websocket");


const CallLogsPage = () => {
  const { callLogs, getAllCallLogs } = useCallLogs();
  const { user } = useUserAuth();
  const [ws, setWs] = React.useState<WebSocket | null>(null);
 

  useEffect(() => {
    if (user?.id) {
      getAllCallLogs({
        hospitalId: user?.id,
      });
    }
  }, [user?.id, getAllCallLogs]);
  return <CallLogsLayout callLogs={callLogs} />;
}

export default CallLogsPage