import React, { useEffect } from 'react'
import LogsLayout from '../../layout/doctor/LogsLayout'
import { useCallLogs } from '../../hooks/useCallLogs';
import { useUserAuth } from '../../provider/AuthProvider';

const LogsPage = () => {
  const { callLogs, getAllCallLogs } = useCallLogs();
  const { user } = useUserAuth();
  useEffect(() => {
    if (user?.id && user?.hospital) {
      getAllCallLogs({
        doctorId: user?.id,
        hospitalId: user?.activeHospital?.id || user?.hospital?.[0]?.id,
      });
    }
  }, [user?.id, user?.hospital]);
  return (
    <LogsLayout name={user?.userName} callLogs={callLogs} />
  )
}

export default LogsPage