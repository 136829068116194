import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { Token } from "../constants/EndPoints";

interface PatientFormRequest {
  date: string;
  name: string;
  age: string;
  gender: string;
  phone: string;
  department: string;
  doctor: string;
}

export const usePatient = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [registeredPatientResponse,setRegisteredPatientResponse] = useState<any>(null)

  const registerPatient = useCallback(
    async (payload: PatientFormRequest) => {
      try {
        setLoading(true);
        const response = await getHttpClient(Token.token, "POST", payload);
        setRegisteredPatientResponse(response.token)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  const values = useMemo(() => {
    return {
      loading,
      registerPatient,
      registeredPatientResponse
    };
  }, [loading, registerPatient,registeredPatientResponse]);

  return values;
};
