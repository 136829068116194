import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { AdminEndpoints } from "../constants/EndPoints";

interface MetaData {
    total_count: number;
    limit: number;
    page: number;
}

export interface DoctorData {
    _id: string;
    hospital: any[];
    email: string;
    password: string;
    department: any[];
    confirmation_token: string | null;
    confirmed_at: string;
    is_active: boolean;
    is_approved: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface DoctorResponseData {
    success: boolean;
    doctors: DoctorData[];
    meta: MetaData;
}

export interface HospitalData {
    _id: string;
    email: string;
    password: string;
    departments: string[];
    confirmationToken: string;
    confirmedAt: string;
    isActive: boolean;
    isApproved: string;
    isOnboard: boolean;
    createdAt: string;
    updatedAt: string;
    address: string;
    city: string;
    name: string;
    phone: string;
    state: string;
}
  
export interface HospitalResponse {
    success: boolean;
    hospitals: HospitalData[];
    meta: MetaData;
}

export interface CountData {
    approvedDoctors: number;
    approvedHospitals: number;
    totalDoctors: number;
    totalHospitals: number;
} 

export const useAdmin = () => {
    const [loading, setLoading] = useState(false);
    const [hospitalList, setHospitalList] = useState<HospitalResponse | null>(null);
    const [doctorList, setDoctorList] = useState<DoctorResponseData | null>(null);
    const [count, setCount] = useState<CountData>({
        approvedDoctors: 0,
        approvedHospitals: 0,
        totalDoctors: 0,
        totalHospitals: 0,
    });

    const getHospitalList = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getHttpClient(AdminEndpoints.adminHospital, 'GET');
            setHospitalList(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const getDoctorList = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getHttpClient(AdminEndpoints.adminDoctor, 'GET');
            setDoctorList(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const getCount = useCallback(async () => {
        try {
            const response = await getHttpClient(AdminEndpoints.getCount, 'GET');
            setCount(response?.data)
        } catch (error) {
        }
    }, []);

    const updateDoctorStatus = useCallback(async (id: string, status: string) => {
        await getHttpClient(`${AdminEndpoints.adminDoctor}/${id}/updateStatus`, 'POST', { status });
        await getDoctorList();
    }, [getDoctorList]);

    const updateHospitalStatus = useCallback(async (id: string, status: string) => {
        await getHttpClient(`${AdminEndpoints.adminHospital}/${id}/updateStatus`, 'POST', { status });
        await getHospitalList();
    }, [getHospitalList]);

    const values = useMemo(() => {
        return {
            loading,
            hospitalList,
            doctorList,
            count,
            getCount,
            getHospitalList,
            getDoctorList,
            updateDoctorStatus,
            updateHospitalStatus,
        }
    }, [loading, hospitalList, doctorList, count, getCount, getHospitalList, getDoctorList, updateDoctorStatus, updateHospitalStatus]);

    return values;
};