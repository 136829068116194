import * as React from "react";
import { useCallback, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box marginTop="24px">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LoginTab = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleLogin = useCallback((route: string) => {
    navigate(route);
  }, [navigate])

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Sign In" {...a11yProps(0)} />
          <Tab label="Sign Up" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Stack direction="column" spacing={3}>
          <Button
            onClick={() => handleLogin('/auth/login/hospital')}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: '50px',
            }}
            variant="outlined"
            fullWidth
          >
            Login for Hospital Front Desk
            <ArrowForwardIcon />
          </Button>
          <Button
            onClick={() => handleLogin('/auth/login/doctor')}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: '50px',
            }}
            variant="outlined"
            fullWidth
          >
            Login for Doctor
            <ArrowForwardIcon />
          </Button>
          <Button
            onClick={() => handleLogin('/auth/login/token-system')}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: '50px',
            }}
            variant="outlined"
            fullWidth
          >
            Login for Token Patient View
            <ArrowForwardIcon />
          </Button>
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Stack direction="column" spacing={3}>
          <Button
            onClick={() => handleLogin('/auth/register/hospital')}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: '50px',
            }}
            variant="outlined"
            fullWidth
          >
            Sign Up for Hospital Front Desk
            <ArrowForwardIcon />
          </Button>
          <Button
            onClick={() => handleLogin('/auth/register/doctor')}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: '50px',
            }}
            variant="outlined"
            fullWidth
          >
            Sign Up for Doctor
            <ArrowForwardIcon />
          </Button>
        </Stack>
      </CustomTabPanel>
    </Box>
  );
};

export default LoginTab;
