import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { CustomCall } from "../constants/EndPoints";
  
export interface CallRecord {
    id: string;
    message: string;
    hospitalId: string;
    doctorId: string;
    isActive: boolean;
}

export const useCustomCall = () => {
    const [loading, setLoading] = useState(false);
    const [callLogs, setCallLogs] = useState<CallRecord[]>([]);

    const getCallLogs = useCallback(async (hospitalId: string) => {
        try {
            setLoading(true);
            const response = await getHttpClient(`${CustomCall.call}?hospitalId=${hospitalId}`, 'GET');
            setCallLogs(response?.response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setCallLogs([]);
        }
    }, []);

    const startCall = useCallback(async (hospitalId: string, id: string) => {
        return await getHttpClient(CustomCall.activeCall, 'POST', { customCallId: id, hospitalId });
    }, []);

    const endCall = useCallback(async (id: string) => {
        return await getHttpClient(`${CustomCall.activeCall}/${id}`, 'PATCH');
    }, []);

    const values = useMemo(() => {
        return {
            loading,
            callLogs,
            startCall,
            endCall,
            getCallLogs,
        }
    }, [loading, callLogs, startCall, endCall, getCallLogs]);

    return values;
};