import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { Fees } from "../constants/EndPoints";

export const useFees = () => {
    const [feeData, setFeeData] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const getFees = useCallback(async (doctorId: string, hospitalId: string) => {
        try {
            setLoading(true);
            const response = await getHttpClient(`${Fees.fee}?hospitalId=${hospitalId}&doctorId=${doctorId}`, 'GET');
            setFeeData(response?.response?.fees);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const saveFees = useCallback(async (hospitalId: string, fees: string) => {
        try {
            setLoading(true);
            const response = await getHttpClient(Fees.fee, 'POST', { fees, hospitalId });
            setFeeData(response?.response?.fees);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    const values = useMemo(() => {
        return {
            loading,
            feeData,
            getFees,
            saveFees,
        }
    }, [loading, feeData, getFees, saveFees]);

    return values;
};