import * as React from 'react';
import {
  useContext,
  createContext,
  useState,
  useMemo,
} from 'react';

type AppLoadingContextType = {
  isAppLoading: boolean;
  setIsAppLoading(f: boolean | ((prev: boolean) => boolean)): void;
};
const AppLoadingContext = createContext({
  isAppLoading: false,
} as AppLoadingContextType);

type AppLoadingProviderProps = {
  children: JSX.Element;
};
export const AppLoadingProvider = ({ children }: AppLoadingProviderProps) => {
  const [isAppLoading, setIsAppLoading] = useState<boolean>(true);

  const values = useMemo(
    () =>
      ({
        isAppLoading,
        setIsAppLoading,
      }) as AppLoadingContextType,
    [
        isAppLoading,
        setIsAppLoading,
    ],
  );

  return <AppLoadingContext.Provider value={values}>{children}</AppLoadingContext.Provider>;
};
export const useLoading = () => {
  return useContext(AppLoadingContext);
};
