import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Typography } from "@mui/material";
import PhoneDisabledOutlinedIcon from "@mui/icons-material/PhoneDisabledOutlined";
import { ActiveCallRecord } from "../hooks/useActiveCall";
import { useUserAuth } from "../provider/AuthProvider";
import { useSocketContext } from "../provider/SocketProvider";

export function CallCard({
  loading,
  call,
  endCall
}: {
  loading: boolean;
  call: ActiveCallRecord;
  endCall: (id: string) => void;

}) {
  const { user } = useUserAuth();
  const { sendMessage } = useSocketContext();

  return (
    <>
      <Box
        sx={{
          borderColor: (theme) => theme.palette.border,
          borderRadius: '4px',
          borderWidth: 1,
          borderStyle: "solid",
          display: "flex",
          justifyContent: "space-between",
          padding: '1rem',
          alignItems: "center",
          mt: '24px'
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <InfoOutlinedIcon sx={{ fontSize: '32px' }}/>
          <Box
            sx={{
              marginLeft: '12px',
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography>
              {call.message} ({call.doctorId.name} - {call.department})
            </Typography>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              Room No: {call.roomNo}
            </Typography>
          </Box>
        </Box>
        <div className="lg:space-x-4 md:space-x-4 sm:space-x-4 space-x-2 flex">
          <Button
            startIcon={
              <PhoneDisabledOutlinedIcon
                sx={{
                  height: 24,
                  width: 24,
                }}
              />
            }
            variant="contained"
            color="error"
            size="large"
            disabled={loading}
            style={{backgroundColor:'red'}}
            onClick={async () => {
              sendMessage(JSON.stringify({
                topic: "active_call",
                callId: call.customCallId,
                from: 'hospital',
                action: 'end'
              }))
              endCall(call.customCallId);
            }}
          >
            End
          </Button>
        </div>
      </Box>
    </>
  );
}
