import React, { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BreadCrumbs from "../../components/BreadCrumbs";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { getHttpClient } from "../../config/AxiosClient";
import { HospitalEndPoints, PaymentEndPoints } from "../../constants/EndPoints";

const PaymentLayout = () => {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Full Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Enter a valid email"),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, "Phone Number must be only digits")
      .min(10, "Phone Number must be at least 10 digits")
      .max(15, "Phone Number must be at most 15 digits")
      .required("Phone Number is required"),
    address: yup.string().required("Address is required"),
    amount: yup.string().required("Amount is required")
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [Razorpay] = useRazorpay();
  const onSubmit = useCallback(async (data: any) => {

    const orderInfo = await getHttpClient(PaymentEndPoints.payment,'POST', { amount: data.amount })
    console.info('=======================')
    console.info(orderInfo)
    console.info('=======================')
    try {

      const payment_amount = parseInt(orderInfo.response.amount)*1000

      const options: RazorpayOptions = {
        key: orderInfo.response.razorpay_id,
        amount: payment_amount.toString(),
        currency: "INR",
        name: data.name,
        description: "Test Transaction",
        order_id: orderInfo.response.order_id,
        handler: (res) => {
          getHttpClient(PaymentEndPoints.verify,'POST', res).then((res) => {
            console.info(res)
            
          }).catch((err) => {
            alert(err)
          })
          console.log(res);
        },
        prefill: {
          name: data.name,
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: data.address,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.open();
    } catch (error) {
      console.error("Payment failed", error);
    }
  },[Razorpay]);

  // const onSubmit = async (data: any) => {

  //   console.log('data', data);
  // };
  return (
    <Stack direction='column' spacing={3}>
      <BreadCrumbs data={["Home", "Payment"]} title='Payment' />
      <Typography variant='h4'>Invoice Info</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction='column' spacing={3} width='50%'>
        <FormControl fullWidth error={!!errors.name}>
            <InputLabel htmlFor='outlined-adornment-name'>
              Billing Name
            </InputLabel>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  id='outlined-adornment-name'
                  label='Billing Name'
                  {...field}
                />
              )}
            />
            {errors.name && (
              <Typography color='error'>{errors.name.message}</Typography>
            )}
          </FormControl>
          <FormControl fullWidth error={!!errors.amount}>
            <InputLabel htmlFor='outlined-adornment-name'>
              Billing Amount
            </InputLabel>
            <Controller
              name='amount'
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  id='outlined-adornment-name'
                  label='Billing Amount'
                  {...field}
                />
              )}
            />
            {errors.name && (
              <Typography color='error'>{errors.name.message}</Typography>
            )}
          </FormControl>
          <FormControl fullWidth error={!!errors.email}>
            <InputLabel htmlFor='outlined-adornment-email'>
              Billing Email
            </InputLabel>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  id='outlined-adornment-email'
                  label='Billing Email'
                  {...field}
                />
              )}
            />
            {errors.email && (
              <Typography color='error'>{errors.email.message}</Typography>
            )}
          </FormControl>
          <FormControl fullWidth error={!!errors.phone}>
            <InputLabel htmlFor='outlined-adornment-phone'>
              Billing Phone Number
            </InputLabel>
            <Controller
              name='phone'
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  id='outlined-adornment-phone'
                  label='Billing Phone Number'
                  {...field}
                />
              )}
            />
            {errors.phone && (
              <Typography color='error'>{errors.phone.message}</Typography>
            )}
          </FormControl>
          <FormControl fullWidth error={!!errors.address}>
            <InputLabel htmlFor='outlined-adornment-address'>
              Billing Address
            </InputLabel>
            <Controller
              name='address'
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  id='outlined-adornment-address'
                  label='Billing Address'
                  {...field}
                />
              )}
            />
            {errors.address && (
              <Typography color='error'>{errors.address.message}</Typography>
            )}
          </FormControl>
          <Button variant='contained' type='submit'>
            Generate Invoice
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default PaymentLayout;
