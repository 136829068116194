import React, { useCallback, useMemo } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import PaymentIcon from '@mui/icons-material/Payment';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import TokenIcon from '@mui/icons-material/Token';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import { useUserAuth } from "../provider/AuthProvider";
import { Roles } from "../constants/Roles";

export const drawerWidth = 240;

const Sidebar = () => {
  const theme = useTheme();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const drawerMenu = useMemo(() => {
    if (user?.role === Roles.doctor) {
      return [
        {
          name: "Profile",
          route: "/doctor/profile",
          icon: <PermContactCalendarOutlinedIcon />,
        },
        {
          name: "Doctor",
          route: "/doctor/dashboard",
          icon: <LocalHospitalIcon />,
        },
        { name: "Log", route: "/doctor/logs", icon: <EventNoteIcon /> },
        {
          name: "Settings",
          route: "/doctor/settings",
          icon: <SettingsOutlinedIcon />,
        },
      ];
    }
    if (user?.role === Roles.admin) {
      return [
        {
          name: "Dashboard",
          route: "/admin/dashboard",
          icon: <PermContactCalendarOutlinedIcon />,
        },
        {
          name: "Doctor",
          route: "/admin/doctor",
          icon: <LocalHospitalIcon />,
        },
        { name: "Hospital", route: "/admin/hospital", icon: <EventNoteIcon /> },
        {
          name: "Settings",
          route: "/admin/settings",
          icon: <SettingsOutlinedIcon />,
        },
      ];
    }
    if (user?.role === Roles.hospital) {
      return [
        {
          name: "Patient Registration",
          route: "/token/new",
          icon: <AppRegistrationIcon />,
        },
        {
          name: "Modify Rooms",
          route: "/token/rooms",
          icon: <MeetingRoomIcon />,
        },
        { name: "Departments", route: "/token/departments", icon: <AutoAwesomeMosaicIcon /> },
        { name: "Doctor Schedule", route: "/token/doctorschedule", icon: <LocalHospitalIcon /> },
        {
          name: "Active Tokens",
          route: "/token/active",
          icon: <TokenIcon />,
        },
        {
          name: "Token Log",
          route: "/token/logs",
          icon: <EventNoteIcon />,
        },
        {
          name: "Active Call",
          route: "/token/active-calls",
          icon: <PhoneEnabledIcon />,
        },
        {
          name: "Call Log",
          route: "/token/call-logs",
          icon: <LibraryBooksIcon />,
        },
        {
          name: "Settings",
          route: "/token/settings",
          icon: <SettingsOutlinedIcon />,
        },
        {
          name: "Payment",
          route: "/token/payment",
          icon: <PaymentIcon />,
        },
      ];
    }
    return [];
  }, [user]);

  const onClickNavigate = useCallback(
    (route: string) => {
      navigate(route);
    },
    [navigate]
  );

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <Header />
      <Drawer
        sx={{
          minWidth: drawerWidth,
          flexShrink: 0,
          border: "none",
          "& .MuiDrawer-paper": {
            minWidth: drawerWidth,
            boxSizing: "border-box",
            border: "none",
          },
        }}
        variant="permanent"
      >
        <Toolbar />
        <List sx={{ paddingLeft: "16px" }}>
          {drawerMenu.map((menu, index) => (
            <Stack
              direction="row"
              spacing={1}
              onClick={() => onClickNavigate(menu.route)}
              sx={{
                padding: "8px 18px",
                marginTop: "12px",
                borderRadius: "8px",
                cursor: "pointer",
                color: location.pathname.toLowerCase() === menu.route?.toLowerCase() ? theme.palette.text.secondary : theme.palette.text.primary,
                background: location.pathname.toLowerCase() === menu.route?.toLowerCase() ? theme.palette.primary.main : theme.palette.background.default,
                "&:hover": {
                    opacity: 0.5,
                }
              }}
              key={index}
            >
              {menu.icon}
              <Typography sx={{ color: location.pathname.toLowerCase() === menu.route?.toLowerCase() ? theme.palette.text.secondary : theme.palette.text.primary }}>
                {menu.name}
              </Typography>
            </Stack>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
