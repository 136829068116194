import React from 'react';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import { useDoctorList } from '../../hooks/useDoctorList';
import { useDoctorSchedule } from '../../hooks/useDoctorSchedule';
import { toast } from 'react-toastify';

interface Props {
  setDoctorSchedule: React.Dispatch<React.SetStateAction<boolean>>
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


const NewScheduleLayout: React.FC<Props> = ({ setDoctorSchedule }) => {

  const [openAdd, setAddOpen] = React.useState(false);
  const { getDoctorList, doctorList } = useDoctorList();
  const [doctorState, setDoctorState] = React.useState<any>([])
  const [slotAvailability, setSlotAvailability] = React.useState<any>([])
  const [openEdit, setEditOpen] = React.useState(false);
  const [editVal, setEditVal] = React.useState({ from: '', to: '', index: null })
  const { createDoctorSchedule } = useDoctorSchedule()

  const validationSchema = yup.object().shape({
    date: yup.date().required('Date is required'),
    doctor: yup.string().required('Doctor is required'),
    perpatientTime: yup.number().required('Per patient time is required')
  });

  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: undefined,
      doctor: '',
      perpatientTime: undefined
    },
  });
  React.useEffect(() => {
    getDoctorList()
  }, [])

  React.useEffect(() => {
    setDoctorState(doctorList)
  }, [doctorList])

  React.useEffect(() => {
    getDoctorList()
  }, [])

  React.useEffect(() => {
    setDoctorState(doctorList)
  }, [doctorList])

  const handleAddOpen = () => {
    setAddOpen(true);
  }


  const onSubmit = (data: any) => {
    try {
      if(slotAvailability.length >= 1) {
        const utcDate = moment(data.date).format('YYYY-MM-DD');
        const selectedDoctor:any = doctorList.filter((doctor)=>doctor.name === data.doctor)
        const selectedDoctorId = selectedDoctor[0]['id']
        let payload = {
          "doctor": selectedDoctorId,
          "date": utcDate,
          "perpatientTime": data.perpatientTime,
          "slot_availability": slotAvailability
        }
        // // remove window.reload
        createDoctorSchedule(payload).then(()=>window.location.reload()).catch((err)=>console.log(err))
        setDoctorSchedule(true)
      }
    
    } catch (error) {
      console.log(error)
    }
  }

  const onEditHandler = (availability: any, index: any) => {
    setEditOpen(true)
    let start = availability.startTime;
    let end = availability.endTime
    setEditVal({ from: start, to: end, index: index })
  }

  const onDeleteHandler = (availability: any, index: any) => {
    let filteredData: any = slotAvailability.filter((s: any, i: any) => i !== index)
    setSlotAvailability(filteredData)
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>

        <Typography variant='h4'>New Schedule</Typography> <br />

        <FormControl fullWidth error={!!errors.doctor}>
          <InputLabel htmlFor="select-doctor">Doctor</InputLabel>
          <Controller
            name="doctor"
            control={control}
            render={({ field }) => (
              <Select id="select-doctor" label="doctor"  {...field}>
                {doctorState?.map((item: any) => (
                  <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.doctor && <Typography color="error">{errors.doctor.message}</Typography>}
        </FormControl>
        <br /><br />
        <FormControl fullWidth error={!!errors.date}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => <DatePicker label="Add date" value={field.value || null} onChange={(newdate) => {
              if (newdate) {
                field.onChange(newdate)
              }
            }} />}
          />
          {errors.date && <Typography color="error">{errors.date.message}</Typography>}
        </FormControl>

        <br /><br />

        <FormControl error={!!errors.perpatientTime}>
          <InputLabel htmlFor="outlined-adornment-perpatienttime">perpatienttime</InputLabel>
          <Controller
            name="perpatientTime"
            control={control}
            render={({ field }) => <OutlinedInput type="number" label="outlined-adornment-perpatienttime" placeholder='00:00:00' id="perpatienttime" {...field} />}
          />
          {errors.perpatientTime && <Typography color="error">{errors.perpatientTime.message}</Typography>}
        </FormControl>
        <br /><br />

        <Button onClick={handleAddOpen} variant="contained">Add Slot</Button><br /><br />
        {slotAvailability?.length === 0 && <Typography color="error">Please fill the time slot</Typography>}
        {slotAvailability.length >= 1 &&
          slotAvailability.map((availability: any, index: any) => {
            return (
              <Box style={{ width: '100%', height: '10vh', display: 'flex', flexWrap: 'wrap' }}>
                <Stack style={{ height: '70px', width: '44%' }} direction='row'>
                  <Typography style={{ backgroundColor: '#FAFAFA', justifyContent: 'center', width: '49%', paddingLeft: '7%', padding: '1%', borderRadius: '10px', alignContent: 'center' }}>{availability.startTime} - {availability.endTime} </Typography>
                </Stack>
                <Stack style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', width: '100px' }}>
                  <IconButton onClick={() => onEditHandler(availability, index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => onDeleteHandler(availability, index)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Box>)
          })

        }
        <br />
        {/**Footer */}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant='contained' onClick={() => { setDoctorSchedule(true) }} style={{ backgroundColor: 'white', color: 'black' }}>Cancel</Button>
          <Button variant='contained' type="submit">Submit</Button>
        </Stack>
        {/**Footer */}

        {/**Add Modal */}

        <Modal
          open={openAdd}
          onClose={() => { }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddSlot setAddOpen={setAddOpen} setSlotAvailability={setSlotAvailability} />

        </Modal>

        {/**Add Modal */}

        {/**Edit Modal */}

        <Modal
          open={openEdit}
          onClose={() => { setEditOpen(false) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <EditSlot setEditOpen={setEditOpen} slotAvaiability={slotAvailability} index={editVal.index} setSlotAvailability={setSlotAvailability} from={editVal.from} to={editVal.to} />

        </Modal>

        {/**Edit Modal */}


      </form>
    </Box>
  )
}

export default NewScheduleLayout



const AddSlot = (props: any) => {

  const [from, setFrom] = React.useState("")
  const [to, setTo] = React.useState("")

  const handleFromTimePicker = (newDate: any) => {
    let filteredDate = newDate.target.value
    if (filteredDate) {
      setFrom(filteredDate)
    }
  }

  const handleToTimePicker = (newDate: any) => {
    let filteredDate = newDate.target.value
    if (filteredDate) {
      setTo(filteredDate)
    }
  }

  const onSubmitHandler = (e: any) => {
    e.preventDefault()
    props.setSlotAvailability((prev: any) => [...prev,
    {
      "startTime": from,
      "endTime": to
    }
    ])
    setFrom("")
    setTo("")
    //close the popup
    props.setAddOpen(false)
  }


  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h4" component="h2">
        Add Slot
      </Typography>
      <br />
      <form onSubmit={onSubmitHandler}>
        <Stack direction='column' spacing={4}>
          <h4>Available From</h4>
          <input type="time" onChange={(e) => handleFromTimePicker(e)} style={{ width: '550px', height: '50px',borderRadius:"10px" }} />
          <h4>Available To</h4>
          <input type="time" onChange={(e) => handleToTimePicker(e)} style={{ width: '550px', height: '50px',borderRadius:"10px" }} />
        </Stack>
        <br />
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <Button variant='contained' onClick={() => { props.setAddOpen(false) }} style={{ backgroundColor: 'white', color: 'black' }}>Cancel</Button>
          <Button variant="contained" type="submit">Add</Button>
        </Stack>
      </form>
    </Box>
  )
}


const EditSlot = (props: any) => {

  const [from, setFrom] = React.useState(props.from)
  const [to, setTo] = React.useState(props.to)

  const handleFromTimePicker = (newDate: any) => {
    let filteredDate = newDate.target.value
    if (filteredDate) {
      setFrom(filteredDate)
    }
  }

  const handleToTimePicker = (newDate: any) => {
    let filteredDate = newDate.target.value
    if (filteredDate) {
      setTo(filteredDate)
    }
  }

  const onSubmitHandler = (e: any) => {
    e.preventDefault()
    props.slotAvaiability[props.index] = {
      "startTime": from,
      "endTime": to
    }
    setFrom("")
    setTo("")
    //close the popup
    props.setEditOpen(false)
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h4" component="h2">
        Edit Slot
      </Typography>
      <br />
      <form onSubmit={onSubmitHandler}>
        <Stack direction='column' spacing={4}>
          <h4>Available From</h4>
          <input type="time" value={from} onChange={(e) => handleFromTimePicker(e)} style={{ width: '400px', height: '50px' }} />
          <h4>Available To</h4>
          <input type="time" value={to} onChange={(e) => handleToTimePicker(e)} style={{ width: '400px', height: '50px' }} />
        </Stack>
        <br />
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <Button variant='contained' onClick={() => { props.setEditOpen(false) }} style={{ backgroundColor: 'white', color: 'black' }}>Cancel</Button>
          <Button variant="contained" type="submit">Update</Button>
        </Stack>
      </form>
    </Box>
  )
}