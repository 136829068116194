import { useCallback, useMemo, useState } from "react";
import { getHttpClient } from "../config/AxiosClient";
import { Logs } from "../constants/EndPoints";
  
export interface CallRecord {
    id: string;
    message: string;
    hospitalId: string;
    doctorId: string;
    startTime: string;
    endTime: string;
    endBy: string;
    isActive: boolean;
}

export const useCallLogs = () => {
    const [loading, setLoading] = useState(false);
    const [callLogs, setCallLogs] = useState<CallRecord[]>([]);

    const getAllCallLogs = useCallback(
        async ({
            doctorId,
            hospitalId,
        }: {
            doctorId?: string;
            hospitalId?: string;
        }) => {
            try {
            setLoading(true);
            const params = new URLSearchParams();
            if (doctorId) {
                params.append("doctorId", doctorId);
            }
            if (hospitalId) {
                params.append("hospitalId", hospitalId);
            }
            const response = await getHttpClient(
                `${Logs.callLogs}?${params.toString()}`,
                "GET"
            );
            setCallLogs(response?.response);
            setLoading(false);
            } catch (error) {
            setLoading(false);
            setCallLogs([]);
            }
        },
        []
    );

    const values = useMemo(() => {
        return {
            loading,
            callLogs,
            getAllCallLogs,
        }
    }, [loading, callLogs, getAllCallLogs]);

    return values;
};