import { Box, Button, IconButton, Modal, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useDoctorSchedule } from '../../hooks/useDoctorSchedule';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ViewDoctorSlots = (props: any) => {
  const { viewDoctorScheduleData, viewDoctorSchedule, deleteTimeSlot } = useDoctorSchedule();
  const [doctorDetails, setDoctorDetails] = useState<any>([]);
  const [editTimeSlotData, setEditTimeSlotData] = useState({ startTime: '', endTime: '', slotId: '', docId: '' });
  const [openEditTimeSlot, setEditTimeSlot] = useState(false);
  const [openAddTimeSlot, setAddTimeSlot] = useState(false);
  const [addTimeSlotId, setAddTimeSlotId] = useState("");

  useEffect(() => {
    // getting doctor id from props
    let docId = props.doctorDetails.id;
    viewDoctorSchedule(docId);
  }, [props.doctorDetails.id]);

  useEffect(() => {
    setDoctorDetails(viewDoctorScheduleData);
  }, [viewDoctorScheduleData]);

  const onEditHandler = (availability: any, index: any, detail: any) => {
    // call edit Handler api function
    setEditTimeSlot(true);
    const id = detail.id;
    setEditTimeSlotData({ startTime: availability.startTime, endTime: availability.endTime, slotId: availability.id, docId: id });
  };

  const onDeleteHandler = (availability: any, index: any, detail: any) => {
    // call delete Handler api function
    const slotId = availability.id;
    const docId = detail.id;
    deleteTimeSlot(docId, slotId).then(() => viewDoctorSchedule(props.doctorDetails.id));
  };

  const AddSlotHandler = (detailId: string) => {
    setAddTimeSlot(true);
    setAddTimeSlotId(detailId);
  };

  return (
    <Box>
      <Typography id="modal-modal-title" variant="h4" component="h2">
        View Schedule
      </Typography><br />
      <TextField id="filled-basic" label="Doctor" defaultValue={props.doctorDetails.name}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
      />
      <br /><br />
      <Typography id="modal-modal-title" variant="h4" component="h2">
        Booked Slots
      </Typography><br />
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tr>
          <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #dddddd" }}>Available On</th>
          <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #dddddd" }}>Available From</th>
          <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #dddddd" }}>Available To</th>
          <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #dddddd" }}></th>
        </tr>
        {doctorDetails.length >= 1 && doctorDetails.map((detail: any, index: any) => {
          return (
            <tr key={index}>
              <td style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #dddddd" }}>{`${detail?.date_string} | ${detail?.day}` || 'No date'}</td>
              <td style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #dddddd" }}>
                {detail.slotAvailability.map((availability: any) => {
                  return (
                    <>
                      {availability?.startTime || 'No time'}<div></div><br />
                    </>
                  )
                })}
              </td>
              <td style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #dddddd" }}>
                {detail.slotAvailability.map((availability: any, i: any) => {
                  return (
                    <>
                      {availability?.endTime || 'No time'}<IconButton style={{ color: "black" }} onClick={() => onEditHandler(availability, i, detail)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton style={{ color: "red" }} onClick={() => onDeleteHandler(availability, i, detail)}>
                        <DeleteIcon />
                      </IconButton><br /><br />
                    </>
                  )
                })}
              </td>
              <td style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #dddddd" }}> <Button onClick={() => AddSlotHandler(detail?.id)}>+ Add slot</Button></td>
            </tr>
          )
        })}
      </table><br />
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button variant='contained' onClick={props.handleClose}>Cancel</Button>
      </Stack>

      <Modal
        open={openEditTimeSlot}
        onClose={() => { setEditTimeSlot(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditTimeSlot viewDoctorScheduleHandler={() => viewDoctorSchedule(props.doctorDetails.id)} editTimeSlotData={editTimeSlotData} setEditTimeSlotData={setEditTimeSlotData} setEditTimeSlot={setEditTimeSlot} />
      </Modal>

      <Modal
        open={openAddTimeSlot}
        onClose={() => { setAddTimeSlot(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddTimeSlot setAddTimeSlot={setAddTimeSlot} id={addTimeSlotId} viewSlotId={props.doctorDetails.id} viewDoctorScheduleHandler={() => viewDoctorSchedule(props.doctorDetails.id)} />
      </Modal>

    </Box>
  )
}

export default ViewDoctorSlots;

const AddTimeSlot = (props: any) => {

  const [payload, setPayload] = useState({ startTime: '', endTime: '' });
  const { addTimeSlot } = useDoctorSchedule();

  const handleFromTimePicker = (e: any) => {
    setPayload({ ...payload, startTime: e.target.value });
  }

  const handleToTimePicker = (e: any) => {
    setPayload({ ...payload, endTime: e.target.value });
  }

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    addTimeSlot(props.id, payload).then(() => {
      props.viewDoctorScheduleHandler();
      props.setAddTimeSlot(false);
      setPayload({ startTime: '', endTime: '' });
    });
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h4" component="h2">
        Add Slot
      </Typography>
      <br />
      <form onSubmit={onSubmitHandler}>
        <Stack direction='column' spacing={4}>
          <h4>Available From</h4>
          <input type="time" value={payload.startTime} onChange={(e) => handleFromTimePicker(e)} style={{ width: '550px', height: '50px', borderRadius: "10px" }} />
          <h4>Available To</h4>
          <input type="time" value={payload.endTime} onChange={(e) => handleToTimePicker(e)} style={{ width: '550px', height: '50px', borderRadius: "10px" }} />
        </Stack>
        <br />
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <Button variant='contained' onClick={() => { props.setAddTimeSlot(false) }} style={{ backgroundColor: 'white', color: 'black' }}>Cancel</Button>
          <Button variant="contained" type="submit">Add Time Slot</Button>
        </Stack>
      </form>
    </Box>
  )
}

const EditTimeSlot = (props: any) => {

  const { editTimeSlot } = useDoctorSchedule();

  const handleFromTimePicker = (e: any) => {
    props.setEditTimeSlotData((prev: any) => ({ ...prev, startTime: e.target.value }));
  }

  const handleToTimePicker = (e: any) => {
    props.setEditTimeSlotData((prev: any) => ({ ...prev, endTime: e.target.value }));
  }

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    const payload = {
      startTime: props.editTimeSlotData.startTime,
      endTime: props.editTimeSlotData.endTime
    };
    editTimeSlot(props.editTimeSlotData.docId, props.editTimeSlotData.slotId, payload).then(() => {
      props.viewDoctorScheduleHandler();
      props.setEditTimeSlot(false);
    });
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h4" component="h2">
        Edit Slot
      </Typography>
      <br />
      <form onSubmit={onSubmitHandler}>
        <Stack direction='column' spacing={4}>
          <h4>Available From</h4>
          <input type="time" value={props.editTimeSlotData.startTime} onChange={(e) => handleFromTimePicker(e)} style={{ width: '550px', height: '50px', borderRadius: "10px" }} />
          <h4>Available To</h4>
          <input type="time" value={props.editTimeSlotData.endTime} onChange={(e) => handleToTimePicker(e)} style={{ width: '550px', height: '50px', borderRadius: "10px" }} />
        </Stack>
        <br />
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <Button variant='contained' onClick={() => { props.setEditTimeSlot(false) }} style={{ backgroundColor: 'white', color: 'black' }}>Cancel</Button>
          <Button variant="contained" type="submit">Update</Button>
        </Stack>
      </form>
    </Box>
  )
}
