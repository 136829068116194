import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography, useTheme } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LogoutIcon from '@mui/icons-material/Logout';
import { Images } from "../assets/images";
import { useNavigate } from "react-router-dom";
import { setUserSessionToken } from "../config/AxiosClient";
import { useUserAuth } from "../provider/AuthProvider";
import { Roles } from "../constants/Roles";
import { useDoctor } from "../hooks/useDoctor";

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, setUser } = useUserAuth();
  const {activeHospital} = useDoctor();

  const onClickLogout = React.useCallback(() => {
    localStorage.clear();
    setUserSessionToken(null);
    setUser(null);
    navigate('/auth');
  }, [navigate, setUser]);

  const copyToClipboard = (userid?:string) => {
    if(userid){
      navigator.clipboard.writeText(userid).then((res)=>{}).catch((err)=>console.log(err))
    }
  }

  const onHospitalChangeHandler = async (e:any) => {
    await activeHospital(e.target.value)

        setUser((prev:any) => ({
          ...prev,
          activeHospital:{
            ...prev.activeHospital,
            id : e.target.value
          }
        }))

  }

  return (
    <AppBar position="fixed" style={{ zIndex: theme.zIndex.drawer + 1, padding: '0 100px', boxShadow: 'none', borderBottom: `1px solid ${theme.palette.border}`, background: theme.palette.background.default }}>
      <Toolbar sx={{ padding: "0px !important" }}>
        <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between" alignItems="center">
            <img style={{ maxHeight: '40px' }} alt="logo" src={Images.logoLight} />
            <Stack direction="row" spacing={2}>
              { user?.role === Roles.hospital && (
                <>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body1">Hospital Name:</Typography>
                    <Button variant="contained" endIcon={<ContentCopyIcon />} onClick={()=>copyToClipboard(user.id)}>
                      {user?.userName.length>=1 ? user?.userName : user?.id}
                    </Button>
                  </Stack>
                </>
              ) }
              { user?.role === Roles.doctor && (
                  <FormControl style={{width:'200px'}}>
                  <InputLabel htmlFor="select-hospital">Select Hospital</InputLabel>
                <Select id="select-hospital" label="Select Hospital"  onChange={onHospitalChangeHandler}>
                  {user?.hospital?.map((item,index) => (
                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
                </FormControl>
              )}
              <Button onClick={onClickLogout} variant="contained" endIcon={<LogoutIcon />}>
                Logout
              </Button>
            </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
