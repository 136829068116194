import React from 'react'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

interface CountCardProps {
    title: string;
    count: number;
}

const CountCard = ({ title, count }: CountCardProps) => {
  return (
    <Card>
        <CardContent>
            <Typography variant='subtitle1' marginBottom={2}>{title}</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
                <PermIdentityOutlinedIcon />
                <Box>
                    <Typography variant='subtitle1'>{count}</Typography>
                    <Typography>Total</Typography>
                </Box>
            </Stack>
        </CardContent>
    </Card>
  )
}

export default CountCard