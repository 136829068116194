import { useCallback, useMemo } from "react";
import { getHttpClient, setUserSessionToken } from "../config/AxiosClient";
import { AuthEndPoints } from "../constants/EndPoints";
import { StorageService } from "../services/StorageService";
import { useNavigate } from "react-router-dom";
import { RouterName } from "../constants/RouteName";
import { HospitalProps, UserStatus, useUserAuth } from "../provider/AuthProvider";
import { Roles } from "../constants/Roles";

export const useAuth = () => {
    const navigate = useNavigate();
    const { setUser } = useUserAuth();

    const onSuccess = useCallback((role: Roles, token: string, path: string, id: string, email: string, isApproved: UserStatus, isOnboard: boolean, hospital?: HospitalProps[],about?:string,department?:any,phone?:any,uName?:string,activeHospital?:any) => {
        StorageService.authToken.setValue(token);
        setUserSessionToken(token);
        setUser(prev => ({
            ...prev,
            id,
            email,
            authToken: token,
            role,
            userName: uName || '',
            isApproved,
            isOnboard,
            hospital,
            about,
            department,
            phone,
            activeHospital
        }));
        navigate(path);
    }, [navigate, setUser]);

    const navigateToRegister = useCallback((path: string) => {
        navigate(path);
    }, [navigate]);

    const login = useCallback(async (role: Roles, email: string, password: string) => {
        if (role === Roles.hospital) {
            const response = await getHttpClient(AuthEndPoints.hospitalLogin, 'POST', { email, password });
            if (!response?.user?.isOnboard) {
                navigateToRegister(RouterName.hospital.registerHospital);
                return;
            }
            if (response?.user?.isApproved !== 'approved') {
                return 'error';
            }
            onSuccess(role, response.token, RouterName.hospital.callLogs, response?.user?.id, response?.user?.email, response?.user?.isApproved, response?.user?.isOnboard, undefined, undefined, response?.user?.department);
        }
        if (role === Roles.tokenSystem) {
            const response = await getHttpClient(AuthEndPoints.tokenSystemLogin, 'POST', { email, password });
            onSuccess(role, response.token, RouterName.tokenSystem.dashboard, response?.user?.id, response?.user?.email, response?.user?.isApproved, response?.user?.isOnboard);
        }
        if (role === Roles.doctor) {
            const response = await getHttpClient(AuthEndPoints.doctorLogin, 'POST', { email, password });
            if (!response?.user?.isOnboard) {
                navigateToRegister(RouterName.doctor.registerDoctor);
                return;
            }
            const hospitalList = response?.user?.hospital?.map((item: any) => ({ id: item?._id, name: item?.name, city: item?.city }));
            onSuccess(role, response.token, RouterName.doctor.dashboard, response?.user?.id, response?.user?.email, response?.user?.isApproved, response?.user?.isOnboard, hospitalList,response?.user?.about,response?.user?.department[0],response?.user?.phone,response?.user?.name,response?.user?.activeHospital);
        }
        if (role === Roles.admin) {
            const response = await getHttpClient(AuthEndPoints.adminLogin, 'POST', { email, password });
            onSuccess(role, response.token, RouterName.admin.doctor, response?.user?.id, response?.user?.email, response?.user?.isApproved, response?.user?.isOnboard);
        }
    }, [onSuccess, navigateToRegister]);

    const register = useCallback(async (role: Roles, email: string, password: string, confirmPassword: string) => {
        if (role === 'Hospital') {
            await getHttpClient(AuthEndPoints.hospitalRegister, 'POST', { email, password, confirmPassword });
        }
        if (role === 'Doctor') {
            await getHttpClient(AuthEndPoints.doctorRegister, 'POST', { email, password, confirmPassword });
        }
    }, []);

    const verifyOTP = useCallback(async (role: Roles, token: string) => {
        if (role === 'Hospital') {
            await getHttpClient(AuthEndPoints.verifyHospitalOTP, 'POST', { token });
            navigate(RouterName.hospital.registerHospital);
        }
        if (role === 'Doctor') {
            await getHttpClient(AuthEndPoints.verifyDoctorOTP, 'POST', { token });
            navigate(RouterName.doctor.registerDoctor);
        }
    }, [navigate]);

    const values = useMemo(() => {
        return {
            login,
            register,
            verifyOTP,
        }
    }, [login, register, verifyOTP]);

    return values;
};