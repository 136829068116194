import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { theme } from "../theme/createTheme";
import Router from "../router/Router";
import { AuthProvider } from "./AuthProvider";
import { AppLoadingProvider } from "./AppLoadingProvider";
import SocketProvider from "./SocketProvider";

const AppProvider = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppLoadingProvider>
        <AuthProvider>
          <SocketProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </LocalizationProvider>
          </SocketProvider>
        </AuthProvider>
      </AppLoadingProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
