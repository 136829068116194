import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ActiveTokenContainerProps {
    title: string;
    count: number;
    onClick: () => void;
}

const ActiveTokenContainer = ({ title, count, onClick }: ActiveTokenContainerProps) => {
  const theme = useTheme();
  return (
    <Box marginBottom={2} onClick={onClick} padding="24px" border={`1px solid ${theme.palette.border}`} borderRadius="8px" sx={{ cursor: 'pointer' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant='body1'>{title}</Typography>
            <Stack direction="row" spacing={2} alignItems="center">
                <Typography padding="8px 14px" sx={{ background: theme.palette.primary.main, borderRadius: '8px', color: theme.palette.text.secondary }}>{count}</Typography>
                <ArrowForwardIosIcon />
            </Stack>
        </Stack>
    </Box>
  )
}

export default ActiveTokenContainer