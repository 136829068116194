import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import AdminDoctorLayout from '../../layout/admin/AdminDoctorLayout'
import { useAdmin } from '../../hooks/useAdmin';

const AdminDoctorPage = () => {
  const { loading, doctorList, getDoctorList, updateDoctorStatus } = useAdmin();
  useEffect(() => {
    getDoctorList();
  }, []);
  return (
    <Box>
      <AdminDoctorLayout doctorList={doctorList} updateDoctorStatus={updateDoctorStatus} />
    </Box>
  )
}

export default AdminDoctorPage