import React, { useCallback, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DataGridContainer from "../../components/DataGrid";
import BreadCrumbs from "../../components/BreadCrumbs";
import { ItemDetails } from "../../hooks/useDoctorList";
import { useUserAuth } from "../../provider/AuthProvider";
import ModifyRoomModal from "../../components/ModifyRoomModal";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

const ModifyRoomLayout = ({
  doctorList,
  isLoading,
  saveRoom,
}: {
  doctorList: ItemDetails[];
  saveRoom: (id: string, room: number) => Promise<void>;
  isLoading: boolean;
}) => {
  const { user } = useUserAuth();
  const [selectedDetails, setSelectedDetails] = useState<ItemDetails | null>(
    null
  );
  const [open, setOpen] = useState<boolean>(false);
  const { lastMessage } = useSocketContext();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onClickEdit = useCallback((data: ItemDetails) => {
    setSelectedDetails(data);
    setOpen(true);
  }, []);

  const columns: GridColDef[] = [
    { field: "department", headerName: "Department", flex: 1 },
    {
      field: "name",
      headerName: "Doctor Name",
      flex: 1,
    },
    {
      field: "roomNo",
      headerName: "Room No",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Add/Edit Room",
      flex: 1,
      renderCell: (row) => (
        <IconButton onClick={() => onClickEdit(row.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = lastMessage.data;
      const msg = JSON.parse(message);
      if (
        msg.topic === "active_call" &&
        user?.id === msg.hospitalId &&
        msg.from === "doctor"
      ) {
        // reload the page
        //const audio = new Audio(require("../../assets/notification.wav"));
        //audio.play();
        // alert(`Dr ${msg.doctorName} has ${msg.action} the ${msg.message}`);
        // if (typeof window !== "undefined") {
        //   window.location.reload();
        // }
      }
    }
  }, []);

  return (
    <Box>
      <BreadCrumbs
        data={["Home", "Token", "Modify Rooms"]}
        title="Modify Rooms"
      />
      <Typography sx={{ marginTop: "24px", marginBottom: "24px" }} variant="h4">
        Rooms
      </Typography>
      <DataGridContainer columns={columns} rows={doctorList} />
      <ModifyRoomModal
        isLoading={isLoading}
        saveRoom={saveRoom}
        open={open}
        handleClose={handleClose}
        selectedDetails={selectedDetails}
      />
    </Box>
  );
};

export default ModifyRoomLayout;
