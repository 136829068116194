import React from 'react'
import { Grid } from '@mui/material'
import { Images } from '../assets/images'
import DoctorRegisterForm from '../components/DoctorRegisterForm'

const RegisterDoctorDetails = () => {
  return (
    <Grid container spacing={4} sx={{ padding: '60px' }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <DoctorRegisterForm />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <img alt="Banner" src={Images.banner} style={{ maxHeight: '60vh' }} />
      </Grid>
    </Grid>
  )
}

export default RegisterDoctorDetails